//  ================================================
//  Colors
//  ================================================


//  ------------------------------------------------
//
//  mixin: rgba
//
//  Parameter:
//
//  @param: {property} $color: background-color
//  @param: {value} $style: 0 - 1
//
//
//  ------------------------------------------------
//
//
//  Examples:
//
//  @include rgba(#000, .5); // black with 50% transparenz
//
//
//  ------------------------------------------------

@mixin rgba($color, $opacity) {

    background-color: $color;
    background-color: rgba($color, $opacity);

}

@mixin glow($color: $shadow) {
    box-shadow: 0px 0px 4px 1px $color;
}

@mixin light-glow($color: $shadow) {
  box-shadow: 0px 0px 20px 0px $color;
}
