@import "resources.scss";
.rental-pickup {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: white;
  justify-content: space-between;

  &-image {
    margin-left: 24px;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      max-height: 100%;
      width: auto;
      max-width: 56px;
    }
  }

  strong {
    font-weight: 500;
    margin-bottom: 4px;
    display: block;
  }

  &-row + &-row {
    margin-top: 8px;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row-reverse;
    justify-content: start;
    padding: 12px 24px;

    &-image {
      margin-right: 24px;
      margin-left: 0;
    }

    strong {
      margin-bottom: 0;
      display: inline;
    }

    &-row + &-row {
      margin-top: 0;
    }
  }
}
