@import "resources.scss";
.tcp-legal-notice {
  @include make-box;
  padding: 20px;

  ul li {
    list-style: none;
  }

  &-terms {
    position: relative;
    display: inline-block;
    padding-top: 15px;
  }

  @include media-breakpoint-up(sm) {
  }
}
