$spacer: 1rem;
$spacers: ( 
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 2),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6.5)
);

$xy-border: (
  top: t, 
  bottom: b, 
  left: l, 
  right: r
);

// padding and spacers
@each $prop, $letter in (margin: m, padding: p) { 
  @each $direction, $d in $xy-border {
    @each $unit, $rem in $spacers {
      .#{$letter}#{$d}-#{$unit} {
        #{$prop}-#{$direction}: #{$rem};
      }
      .#{$letter}x-#{$unit} {
        #{$prop}: 0 $rem;
      }
      .#{$letter}y-#{$unit} {
        #{$prop}: $rem 0;
      }
    }
  }
}
