@import "resources.scss";
.lp-booked-info {
  @extend %is-font-medium;

  background: $gray-background;
  font-size: 12px;
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;;

  &-label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &-passenger {
    margin-bottom: 5px;
  }

  &-ticket {
    margin-bottom: 5px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
    i {
      color: $blue;
      vertical-align: middle;
    }
  }

  & > div {
    margin-bottom: 15px;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    padding: 30px;

    & > div {
      max-width: 40%;
    }
  }
}
