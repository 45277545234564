@import "resources.scss";
.searchbar-filter {
  position: relative;
  display: flex;
  margin-bottom: 16px;

  .fac-checkbox-o {
    svg {
      width: 20px;
      margin-right: 8px;
    }
  }

  @include media-breakpoint-down(sm) {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    max-width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding-bottom: 250px;
    margin-bottom: -234px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
