@import "resources.scss";
.tcp-transport-segment {
  @include tn(opacity);
  display: flex;
  margin-bottom: 16px;

  &-connect {
    height: auto;
    width: 1px;
    background-color: $dark-gray;
    margin: 4px 11px;
    position: relative;

    &::before,
    &::after {
      @include circle(7px);

      content: '';
      border: 1px solid $dark-gray;
      background-color: #fff;
      position: absolute;
      left: -3px;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &-item {
    @include caption;

    &:first-child {
      margin-bottom: 12px;
    }
  }

  &-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      white-space: nowrap;
    }
  }

  &-direction {
    color: $grey500;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      margin-top: -2px;
    }

    i {
      font-size: 14px;
      margin-right: 5px;
      padding-left: 0;
      vertical-align: middle;
    }

    div + div {
      &:before {
        content: '-';
        display: inline-block;
        margin: 0 5px;
      }
    }
  }

  .product-icon {
    height: 14px;
    width: 14px;
  }

  @include media-breakpoint-up(sm) {
    &-item {
      width: 100%;

      &:before {
        @include pos(3px n n 58px, $z: 1);
      }

      &:after {
        @include pos(6px n -6px 62px);
      }

      &-change {
        @include make-icon($icon-repeat);

        &:before {
          font-size: 10px;
          color: #c6c7c8;
          top: 5px;
          left: 56px;
          border-radius: 0;
        }
      }
    }

    &-location {
      display: flex;
      padding-right: 15px;
      align-items: flex-start;
    }

    &-direction {
      color: $gray;
      margin-top: 3px;
    }
  }
}

.is--collapsed .tcp-transport-segment {
  height: 0;
  overflow: hidden;
  opacity: 0;

  &:not(:last-child) {
    margin-bottom: 0;
  }

  &-item {
    padding-bottom: 0;
  }

  &-item:first-child:after {
    border-left: 1px dashed $light-gray;
  }

  &-item:before {
    border-color: $light-gray;
  }

  &-direction {
    opacity: 0;
    height: 0;
  }

  &-expand-icon {
    @include make-icon($icon-angle-down);
  }

 

  &:first-child,
  &:last-child {
    height: auto;
    opacity: 1;
  }

  &:first-child {
    .tcp-transport-segment-item:last-child, .tcp-transport-segment-time span:last-child {
      height: 0;
      opacity: 0;
    }
    .tcp-transport-segment-connect {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  &:last-child {
    margin-top: -6px;

    .tcp-transport-segment-item:first-child, .tcp-transport-segment-time span:first-child {
      height: 0;
      opacity: 0;
    }
    .tcp-transport-segment-connect {
      &:before {
        display: none;
      }
    }
  }
}
