//  ------------------------------------------------
//  Colors
//  ------------------------------------------------
$lighter-gray: #f2f2f3; //#6c6c6e;
$light-gray: #bfbfbf;
$gray: #7f7f7f;
$blue: #0172b1;
$yellow: #fac734;
$red: #dd3728;

$dark-gray: #191f25;
$black: $dark-gray;
$body: $dark-gray;
$white: #fff !default;

$gray-background: #f7f7f7;

$hotels: $body;
$checkout: $body;

$button: #3e3d40;

$shadow: rgba(0, 0, 0, 0.1);

$green: #7cb342;


// new colors
$black50: #F6F9FC;

$black100: #EDF1F5;
$black200: #E5EDF5;
$black400: #98A3B2;
$black500: #7B8694;
$black600: #666F7A;

$grey100: #edf5fd;
$grey200: #e5edf5;
$grey300: #d6dee6;
$grey500: #949ba3;
$grey700: #06121f;
$grey50: #f4f7f9;

$blue10: #E5F0FF;
$blue25: #CCE2FF;
$blue50: #e1f4fc;
$blue300: #5C93DB;
$blue800: #022C63;
$blue900: #004781;

$shadow02: 0px 2px 4px 0px rgba(25, 31, 37, 0.1);

$shadow08: 0px 8px 16px rgba(25, 31, 37, 0.1);

$border-gray: $black200;
