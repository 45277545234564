.Select {
    &-control {
        @include tn(border-color);
        height: $input-height-desktop;
        background: white;
        border: 1px solid $grey500;
        cursor: pointer;

        &:hover {
            border-color: $dark-gray;
        }

        & > *:last-child {
            padding-right: 9px;
        }
    }
    
    &-value {
        line-height: 36px;
    }

    &-menu-outer {
      box-shadow: 0px 2px 4px rgba(25, 31, 37, 0.1);
      margin-top: 8px;
      border: 0;
      padding: 8px 0;
    }

    &-multi {
        height: 100%;
    }

    // &-multi-value-wrapper {
    //     margin-top: -1px;
    // }

    &-placeholder, &--single > &-control &-value {
        line-height: $input-height-desktop - 2;
    }

    &-option {
        @include tn(background);
        padding: 8px;
        color: $dark-gray;

        &.is-selected, &.is-focused {
            background: white;
        }

        &.is-selected {
            color: $blue;
        }

        &:hover {
            background: $blue10;
        }

        div {
            display: flex;
            align-items: center;
        }   
    }

    &-value-label {
        max-width: 93%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        div {
            display: flex;
            align-items: center;
        } 
    }

    &-arrow {
        display: block;
        border: 0;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0z' fill='none' /%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' /%3E%3C/svg%3E");
        background-size: cover;

        .is-open & {
            transform: rotate(180deg);
        }
    }

    &.is-open > .Select-control, &.is-focus > .Select-control {
        border-color: $dark-gray;
        border-radius: 4px;
    }

    &--multi {
        .Select-value {
            background: $lighter-gray;
            color: $body;
            border: 0;
            font-size: 12px;

            &-label {
                padding: 5px 8px;
            }

            &-icon {
                border-right: 1px solid white;
                padding: 3px 8px 5px;

                &:hover {
                    color: inherit;
                    background: $light-gray;
                }
            }
        }
    }

    &--trips {
        .Select-control {
            line-height: 34px;
            width: 140px;

            .Select-value {
                line-height: 34px;
            }
        }
    }

    &--passenger {
      .Select-clear-zone {
        display: none;
      }
    }

    &--window-time {
      text-align: left;

      i {
        vertical-align: text-bottom;
        margin-right: 8px;
      }
    }
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: $body;
}
