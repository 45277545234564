@import "resources.scss";
.rating-icon {
    background: $green;
    display: inline-block;
    margin-right: 2px;
    border-radius: 50%;
    height: 8px;
    width: 8px;

    &-border {
        background: white;
        border: 1px solid $green;
    }

    &.is-large {
        width: 14px;
        height: 14px;
    }

    @include media-breakpoint-up(sm) {
        width: 10px;
        height: 10px;
    }
}

