@import "resources.scss";
.rental {
  border: 1px solid $black200;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;

  &-inner {
    background: white;
    border-bottom: 1px solid $black200;
  }

  &-image {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background: transparent url(../../../assets/img/backgrounds/rental_placeholder.svg) no-repeat
      center / 75px;

    img {
      max-height: 360px;
    }
  }

  &-image,
  &-details-wrapper .rental-section {
    width: 50%;
  }

  &-info {
    padding: 0 16px 16px;
    width: 100%;
  }

  &-section {
    i {
      margin-right: 8px;
    }
  }

  &-option {
    display: flex;
    align-items: center;
  }

  &-section {
    padding-top: 12px;
  }

  &-details-wrapper {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $black200;
  }

  &-label {
    @include caption;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &-option + &-option {
    padding-top: 4px;
  }

  @include media-breakpoint-up(md) {
    &-inner {
      display: flex;
    }

    &-section {
      padding-top: 16px;
      
      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &-info {
      padding: 24px;
    }

    &-image {
      height: auto;
      max-height: 100%;
      background-size: 50px;
      background-color: white;
      max-width: 200px;
      width: 100%;
      border-right: 1px solid $black200;

      img {
        object-fit: contain;
        max-height: auto;
      }
    }

    &-details-wrapper {
      border: 0;

      .rental-section {
        width: 100%;
      }
    }
  }
}

.rental-title {
  padding: 24px 16px 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  background: white;
  font-weight: 500;

  &-name {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.tcp-rental {
  &-image {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    background: $gray-background url(../../../assets/img/backgrounds/rental_placeholder.svg)
      no-repeat center / 75px;
  }

  @include media-breakpoint-up(sm) {
    cursor: default;
    border-radius: 5px;

    &-image {
      @include make-percent-col(25);
      height: auto;
      max-height: 100%;
      background-size: 50px;
      background-color: white;

      img {
        object-fit: contain;
      }
    }
  }
}
