@import "resources.scss";
.tcp-hotel-title {
  margin-bottom: 20px;
    &-stars {
        margin-bottom: 5px;
        line-height: 9px;

        .icon-star {
            font-size: 9px;
            line-height: 1;
            color: $light-gray;

            &:before {
                margin-right: 0;
            }
        }
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 16px;
    }

    &-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;
        margin-bottom: 5px;
    }

    &-location {
      @extend %is-font-medium;

        &-address {
            display: inline-block;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }

        i {
            font-size: 12px;
        }
    }

    &-negotiated-rates {
        @extend %is-font-small-strict;
        background: $light-gray;
        display: inline-block;
        padding: 2px 5px;
        margin-left: auto;
        position: relative;
        margin-bottom: 8px;
        color: white;
        font-weight: bold;
        border-radius: 2px;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
        &-stars {
            margin-top: 0px;
            margin-bottom: 10px;

            .icon-star {
                font-size: 14px;
            }
        }

        &-wrapper {
            margin-bottom: 0;
        }

        &-name {
            max-width: 90%;
        }

        &-location {
            white-space: nowrap;
            margin-bottom: 10px;

            &-address {
                max-width: 80%;
            }
        }
    }

    &-is-favorite {
        color: $red;
    }
}
