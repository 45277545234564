@import "resources.scss";
.tcp-trip-shuttle-type-selector {

  &:first-child {
    margin-bottom: 16px;
  }

  &-transit-title {
    @extend %is-font-medium;
    padding-bottom: 8px;
  }

  &-select {
    margin: 5px 0 5px 0px;
  }

  i {
    margin-right: 8px;
    font-size: 19px;
  }
}
