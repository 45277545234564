@import "resources.scss";
.tcp-hotel-main-image {
    flex-shrink: 0;
    min-width: 100%;
    object-fit: cover;

    @include media-breakpoint-up(sm) {
        min-height: 100%;
    }
}
