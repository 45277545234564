@import "resources.scss";
.demo-banner {
  background: $blue800;
  height: $banner-height;
  padding: 8px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  position: fixed;
  top: 0;
  z-index: 100;

  &-inner {
    @include media-breakpoint-down(sm) {
      animation: slideRightToLeft 10s linear infinite;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
