@import "resources.scss";
.lp-search-bar-vehicles {
  display: flex;
  padding: 4px 0 4px;
  align-items: center;

  &-title {
    font-weight: 500;
    margin-right: 24px;
  }
}
