@import "resources.scss";
.lp-navbar {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $border-gray;
  padding: 0 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  background: white;
  position: fixed;
  z-index: 100;
  top: 0;

  &-subtitle {
    @extend %is-font-small;
    text-transform: none;
  }

  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;


    i {
      font-size: 22px;
    }

    &-left {
      left: 20px;
    }

    &-right {
      right: 20px;
    }

    &-label {
      padding-right: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &-inbound, &-outbound, &-hotel, &-checkout {
    color: white;

    .lp-navbar-button {
      color: white;
    }
  }

  &-inbound {
    background: $red;
  }

  &-outbound {
    background: $blue;
  }

  &-hotel, &-checkout {
    background: $dark-gray;
  }

  .is--demo & {
    top: $banner-height;
  }
}
