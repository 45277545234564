.sidebar-slideout {
  opacity: 0;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;

  .sidebar {
    &-title {
      @extend %is-font-large;

      text-align: center;
      display: none;
    }

    &-input {
      background: white;
      position: relative;
      padding: 15px 16px 20px;
      position: relative;
      width: 100%;

      input {
        border-bottom: 1px solid $light-gray;
        display: block;
        line-height: 15px;
        padding: 0px;
        padding-bottom: 7px;
        width: 100%;
      }

      &-label {
        @extend %is-font-small;

        display: block;
        color: $gray;
        margin-bottom: 5px;
      }
    }

    &-label {
      @extend %is-font-medium;

      margin-bottom: 7px;
    }

    &-inner {
      padding-top: 0;
    }
  }

  &.is-open {
    opacity: 1;
    z-index: $z1;

    // overflow-y: auto;
    height: 100%;
    overflow: scroll;
  }

  @include media-breakpoint-up(sm) {
    left: $widget-width;
    position: absolute;
    top: 0;
    width: 0;
    margin-left: 20px;
    overflow: hidden;

    .sidebar {
      &-title {
        width: $widget-width;
        display: block;
      }
      &-inner {
        padding-top: 20px;
      }
    }

    &.is-open {
      width: $widget-width;
      animation-name: test;
      animation-duration: 0.5s;
      overflow: visible;
      height: auto;
    }
  }
}

@keyframes test {
  0% {
    width: 0;
    overflow: hidden;
  }

  99% {
    width: $widget-width;
    overflow: hidden;
  }

  100% {
    width: $widget-width;
    overflow: visible;
  }
}
