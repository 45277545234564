@import "resources.scss";
.tcp-hotel-details {
  padding: 0px 20px;
  border-top: 1px solid $border-gray;

  &-main, &-side, &-fares {
    padding: 20px 0;
  }

  &-main {
    border-bottom: 1px solid $border-gray;
  }

  &-title {
    @extend %is-font-large;

    font-weight: bold;
    margin-bottom: 10px;
  }

  &-subtitle {
    @include font-medium;
  }

  &-fares {
    width: 100%;
  }

  &-map-title {
    @extend .tcp-hotel-details-title;
  }

  &-map {
    margin: 0 -20px;
    width: 100vw;

    &-element {
      height: 330px;
    }
  }

  &-footer {
    padding: 17px 20px;
    margin: 0 -20px;
    display: flex;
    border-top: 1px solid $border-gray;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;

    .button {
      margin-left: auto;
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &-main {
      border: 0;
      padding-left: 20px;
      margin: 0;

      @include make-percent-col(60);

      &-element {
        height: 365px;
      }
    }

    &-footer {
      display: none;
    }

    &-map-title {
      padding-left: 20px;
    }

    &-map {
      margin: 0;
    }

    &-images {
      width: 100%;
      align-content: center;
      padding-left: 20px;
    }

    &-side {
      @include make-percent-col(40);

      padding-right: 20px;
      padding-left: 40px;
    }

    .lp-booked-info {
      margin: 0;
    }
  }
}
