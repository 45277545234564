@import "resources.scss";
.lp-search-bar-slideout-navbar {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &-close {
    display: flex;
    align-items: center;
    font-weight: 500;

    svg {
      margin-right: 16px
    }
  }

  &-confirm {
    font-weight: 500;
    display: flex;
    align-items: center;

    svg {
      margin-left: 16px;
      transform: rotate(180deg);
    }
  }
}
