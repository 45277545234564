@import "resources.scss";
#map-container {
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include media-breakpoint-up(sm) {
    // min-height: calc(100vh - #{$header-height});
    overflow: hidden;

    > div:first-child {
      position: absolute;
      width: 100%;
      height: 100%;

      > div:first-child {
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    overflow: visible;
  }
}
