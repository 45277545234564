@import "resources.scss";
.search-select {
  position: relative;
  margin-bottom: 16px;

  &-selected li {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;

    svg {
      width: 20px;
      height: 20px;
      fill: $grey700;

      &:hover {
        fill: $black;
      }
    }
  }
}
