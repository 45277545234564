@import "resources.scss";
.tcp-navbar {
    margin-left: auto;
    white-space: nowrap;

    &-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        font-weight: 500;

        &-dropdown {
            text-transform: none;

            &:hover > .tcp-navbar-dropdown-icon {
                transform: rotate(180deg);
            }
        }

        & + & {
            margin-left: 20px;
        }

        i {
            font-size: 18px;
            vertical-align: text-bottom;
            margin-right: 7px;
        }

        &:hover {
            .tcp-navbar-dropdown {
                top: 75px;
                @include tn((top, transform, opacity), (0s, .2s, .2s));
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
        }


    }

    &-dropdown-icon {
      @include tn(transform);
      margin-right: 0 !important;
      margin-left: 5px;
      font-size: 16px;
      display: inline-block;
    }

    &-dropdown {
        @include tn((top, transform, opacity), (0s, .2s, .2s), (.2s, 0s, 0s));
        @include pos(-700px 0 n auto, $z: $z2);
        @extend %reset;
        border-radius: 4px;
        border: 1px solid $border-gray;
        background-color: #fff;
        line-height: 20px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
        transform: translate3d(0, -5px, 0);
        opacity: 0;
        min-width: 250px;
        text-transform: none;

        &-item {
            @include tn(background-color);
            border-bottom: 1px solid $border-gray;
            padding: 15px;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            cursor: pointer;
            background-color: #fff;

            &:hover {
                background-color: $lighter-gray
            }

            &:last-child {
                border-bottom: 0;
            }

            i {
               margin-right: 7px;
               font-size: 14px;
            }
        }
    }
    &:hover {
        .account-bar-dropdown {
            top: 71px;
            @include tn((top, transform, opacity), (0s, .2s, .2s));
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}
