//  ================================================
//  Box
//  ================================================

.box {
 @include make-box();

  &-header {
    border-bottom: 1px solid $light-gray;
    padding: 20px 15px;
  }


  &-top {
    box-shadow: none;
    margin-bottom: 0;
    font-weight: bold;

    .box-item {
      cursor: pointer;
    }

    i {
      margin-left: 5px;
    }
  }

  &-big-padding {
    .box-item {
      padding: 16px 25px;

      @include media-breakpoint-up(sm) {
        padding-left: 12px;
        padding-right: 12px;
      }

      &-approve {
        padding-left: 0;
      }

      &-route {
        padding-left: 0;
      }
    }
  }

  &-approved {
    &.box-cards .box-item-row-bordered {
      border-color: $green; //$blue;
    }
  }

  &-dismissed {
    &.box-cards .box-item-row-bordered {
      border-color: $red;
    }
  }
}


.box-cards {
  margin-bottom: 20px;
  position: relative;

  .box-item {
    display: inline-block;
    width: 24%;
    padding: 10px;
    vertical-align: top;

    &-full {
      width: 100%;
      padding-right: 120px;
    }

    &-wide {
      width: 42%;
    }

    &-middle {
      width: 32%;
    }

    &-small {
      width: 15%;
    }

    &-action {
      @include pos(0 0 0 n);
      border-left: 1px solid $light-gray;
      width: 100px;
      text-align: right;

      button {
        margin-bottom: 5px;
      }
    }

    &-traveller {
      width: 25%;
    }
    &-booked_on {
      width: 17%;
    }
    &-booked_by {
      width: 20%;
    }
    &-price {
      width: 21%;
    }
    &-approve {
      padding-left: 0;
      width: 17%;
      vertical-align: top;

      .button {
        float: right;
      }
    }

    &-route {
      padding-left: 0;
      width: 42%;
    }

    &-sorting-header {
      padding: 0 20px;
      i {
        margin: 0;
        padding: 0;
        &:first-child {
          margin-left: 10px;
        }
      }
    }

    &-date {
      width: 34%;
    }

    &-tabs {
      width: 33%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    &-active {
      color: red;
      border-bottom: 2px solid;
    }

    &-details {
      padding-left: 0;
      width: 24%;
      vertical-align: top;
      button {
        float: right;
      }
    }

    &-row {
      margin-bottom: 2px;
      padding: 5px 0;

      i{
        margin-right: 10px;
      }

      &-bordered {
        border-left: 8px solid $body;
        padding-left: 17px;
      }
    }

  }

  .box {
    &-spacer {
      border-bottom: 1px solid $light-gray;
      margin-left: 25px;
      margin-right: 25px;
      position: relative;
    }



    &-label {
      color: $gray;
      display: inline-block;
      margin-bottom: 3px;
    }

    &-description {
      img {
        max-height: 20px;
        margin-right: 5px;
        vertical-align: text-top;
      }
    }
  }
  .cancellation-label { // NOT USED?
    text-align: right;
    color: $red;
    padding-right: 10px;
  }
  .travel-policy-label {
    text-align: right;
    color: $yellow;
    padding-right: 10px;
  }
}

.box-dark-edge {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
