//  ================================================
//  General
//  ================================================

body {
  background-color: white;
  overflow-y: scroll;
  margin: 0;
  background: $grey50;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

a {
  @include tn(color);
  color: $body;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  &:focus, &:active {
    color: $body;
    text-decoration: none;
  }
}

p {
  margin: 0 0 10px;

  // &:last-child {
  //   // margin-bottom: 0
  // }
}

img {
  max-width: 100%;
}

main {
  overflow: hidden;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-main {
  position: relative;
}

.clear {
  clear: both;
}

.hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

sup {
  top: -.5em;
  font-size: 75%;
  line-height: 0;
}

//  ================================================
//  Forms
//  ================================================

// Input iOS reset
input[type=text], textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

input[type="text"]:disabled {
  background: transparent;
}

// Button reset
button {
  border: 0;
  background: transparent;
  color: $body;
  cursor: pointer;
  outline: 0;
  padding: 0;
  text-align: left;
  &:focus, &:hover, &:active {
    outline: 0;
  }
}

input {
  border: 0;
  outline: none;
}


// Fix chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}



// Placeholder
::placeholder { color: #ccc }

