@import "resources.scss";
.lp-noresults-warning {
  text-align: center;
  padding: 80px 0 0 0;
  margin-bottom: 60px;

  &-title {
    @include f-h2;
    margin-bottom: 15px;
  }

  &-inner {
    padding: 0 30px;
    max-width: 660px;
    margin: 0 auto 30px;
  }

  .button {
    margin: auto;
  }

  &.has--background {
    background: url('../../../assets/img/backgrounds/noresults.png') no-repeat;
    background-size: contain;
    background-position: center bottom;
    padding: 80px 0 260px 0;
  }

  @include media-breakpoint-up(sm) {
    padding-left: 30px;
    margin-left: -30px;
  }
}
