@import "resources.scss";
.breadcrumbs {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    counter-set: breadcrumbs;
    padding: 8px;
    margin-left: auto;

    &-item {
      display: flex;
      align-items: center;

      &.is--active {
        font-weight: 500;

        .breadcrumbs {
          &-label {
            display: flex;
          }
        }
        &::before {
          background-color: $black;
        }
      }

      &::before {
        @include caption;
        @include tn(background-color);
        background-color: $black400;
        margin-right: 8px;
        display: block;
        counter-increment: breadcrumbs;
        content: counter(breadcrumbs);
        width: 16px;
        height: 16px;
        color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        padding-bottom: 1px;
      }

      &.is--clickable {
        &::before {
          cursor: pointer;
        }
        &:hover {
          &::before {
            background-color: $black600;
          }
        }
      }

      &:last-child {
        .breadcrumbs-label::after {
          display: none;
        }
      }
    }

    &-label {
      display: none;
      margin-right: 16px;
      color: $black;
      align-items: center;

      &::after {
        content: '';
        background: $black200;
        height: 26px;
        width: 1px;
        margin-left: 16px;
        display: block;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
  }
}
