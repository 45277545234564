@import "resources.scss";
.skeleton-spinner {
    @include skeleton-animation;

    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    clip-path: circle(40px at center);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    &-circle {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: white;
    }
}
