@import "resources.scss";
.sidebar-route {
  padding: 8px 0 4px;
  display: flex;
  align-items: center;
  
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  }
}
