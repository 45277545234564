@import "resources.scss";
.lp-warning-banner {
  @extend %is-font-medium;
  border-radius: 4px;
  border: 1px solid $red;
  padding: 20px;
  background: white;
  margin: 0 20px 20px;
  font-weight: bold;
  display: flex;

  // align-items: center;
  position: relative;

  &-info {
    border: 1px solid $blue;
    font-weight: normal;
  }

  &-icon {
    margin: 0  20px auto 0;
    flex-shrink: 0;
    width: 30px;
  }

  ul {
    li {
      margin-bottom: 4px;
      font-weight: normal;

      i {
        margin-right: 5px;
      }
    }

    &.error {
      i {
        color: $red;
        font-size: 14px;
      }
    }
  }

  &-close {
    float: right;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    
    i {
      font-size: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    align-items: center;
    margin: 0 0 20px;

    &-icon {
      margin: auto  30px auto 0;
      width: 34px;
    }
  }
}
