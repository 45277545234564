@import "resources.scss";
.tcp-slider-label {
    @extend %is-font-small-strict;

    text-align: center;
    margin-top: 17px;

    // The following use of flex is to satisfy Slider AM/PM Time
    display: flex;
    justify-content: space-between;

    /* IE10 FIX */
    span {
        display: inline-block;
    }

    &-from {
        // The following use of flex is to satisfy Slider AM/PM Time
        text-align: left;
        flex-grow: 1;
    }

    &-to {
        // The following use of flex is to satisfy Slider AM/PM Time
        text-align: right;
        flex-grow: 1;
    }

    &-value {
        @extend %is-bold;

        // The following use of flex is to satisfy Slider AM/PM Time
        flex-grow: 1;
    }
}
