@import "resources.scss";
.searchbar-route-selector {
  &-favorites {
    max-height: 100%;
    overflow: auto;
    margin-top: 8px;
  }

  &-link {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
  }
}
