@import "resources.scss";
.lp-checkout-button-link {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 12px;
}

.seat-warning-icon {
  color: $yellow;
  margin-left: 8px;
}