@import "resources.scss";
.zendesk-demo-chat {
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 88px;
  right: 16px;
  border: 50%;
  margin-top: 0px;
  z-index: 1;
  background: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: $blue300;
  }
}
