@import "resources.scss";
.tcp-hotel-fare-mobile {
    border-bottom: 1px solid $lighter-gray;
    font-size: 12px;
    line-height: 17px;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &-class {
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    &-info {
        @extend %flex-row;

        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;

        & > div {
            @extend %flex-col;
        }

        &-label {

            @extend %is-font-small;
            color: $gray;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 5px;
        }
    }

    &-label {
        display: inline-block;
        font-weight: bold;
        min-width: 90px;
    }

    &-select {
      padding: 20px 0;
      text-align: center;
  }

    // &.is-selected {
    //     border-left-color: $blue;
    // }
}
