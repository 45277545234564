//  ================================================
//  Stars Rating
//  ================================================

.stars-rating {
  font-size: 9px;
  line-height: 9px;
  &-icon {
    @include make-icon($icon-star) {
      margin-right: 0;
    }

    color: $yellow;
    display: inline-block;
    font-size: 9px;
    line-height: 9px;

    &:last-child {
      margin-right: 0;
    }

    &-users {
      color: $green
    }

    &-empty {
      color: $light-gray
    }
    &.is-empty {
      @include make-icon($icon-star-border);
    }
  }

  @include media-breakpoint-up(sm) {
    &-icon {
      font-size: 16px;
      line-height: 1;
    }
  }
}

.color-gray {
  .stars-rating-icon {
    color: $light-gray;
  }
}

