@import "resources.scss";
.lp-booking-info {
  @include make-box;

  padding: 20px;

  &-title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-items {
    display: flex;
    margin: 0 -10px;
  }

  &-item {
    text-align: left;
    margin: 0 10px;
    border: 1px solid $border-gray;
    flex-basis: 33%;
    flex: 1;

    &-title {
      @extend %is-font-large;

      font-weight: bold;
    }

    &-inner {
      @extend %is-font-medium;

      padding: 20px 15px 10px;

      & > div {
        margin-bottom: 10px;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-seat {
      white-space: nowrap;
    }

    &-details {
      flex: 1;
      & > div {
        margin-bottom: 10px;
      }

      .spaced {
        margin-bottom: 20px;
      }
    }

    &-link {
      color: $blue;
      i {
        @extend %is-font-medium;
        margin-right: 5px;
      }

      &-disabled {
        pointer-events: none;
        opacity: .5;
      }
    }

    &-sub_title {
      i {
        vertical-align: text-bottom;
      }
    }

    &-sub_title, &-list_item {
      margin-bottom: 10px;
    }

    @include media-breakpoint-up(sm) {
      &-wrapper {
        flex-direction: row;
      }
    }
  }

  &-sub-total {
    text-align: right;
    padding: 12px 20px;
    background: $gray-background;
    border: 1px solid $border-gray;
    margin-top: 10px;
    margin-bottom: 25px;

    &-price {
      width: 150px;
      font-weight: bold;
      display: inline-block;
    }
  }
}
