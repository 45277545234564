//  ================================================
//  Other Mixins
//  ================================================


//  ------------------------------------------------
//
//  mixin: circle
//
//  Parameter:
//
//  @param: {property} $color: background-color
//  @param: {value} $style: 0 - 1
//
//
//  ------------------------------------------------
//
//
//  Examples:
//
//  @include circle(30px); // circle with 30px width and height, no border
//  @include circle(30px); // circle with 30px width and height, no border
//
//
//  ------------------------------------------------

@mixin circle($value, $border: null) {
    width: $value;
    height: $value;
    display: inline-block;
    @if $border != null { border: $border; }
    border-radius: 100%;
}

@mixin make-percent-col($percent) {
  flex: 0 0 percentage($percent / 100);
  max-width: percentage($percent / 100);
}

@mixin hide-scrollbar() {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}
