@import "resources.scss";
.tcp-search-bar-date-time-selector {
    padding: 0 0 16px;
    text-align: center;

    &-window-time {
        padding: 0 10px 25px;
        margin-bottom: 10px;

        &-direction {
            display: flex;
            align-items: center;
            margin: 24px 0 20px 0px;
        }

        &-label {
            font-weight: 500;
            margin-right: 24px;
        }

        // .fac-radio {
        //     > input[type=radio] + label:before, span:after {
        //         @include body;
        //     }
        // }
    }

    &-rental-time {
      padding: 0 10px 25px;
    }

    .DayPicker-wrapper {
        outline: none;
    }

    .DayPicker-Month {
        margin: 0.7e 0.7em 0;
    }

    .DayPicker-Day {
        border: 0px !important;
        width: 39px;
        height: 39px;
        outline: none;
        padding: 10px;
        border-radius: 0;

        &--today {
            color: $body !important;
            font-weight: bold !important;
        }

        &--outside {
            visibility: hidden !important;
        }

        &--disabled {
            background-color: white !important;
            color: $grey500 !important;
        }

        &--selected {
            color: white !important;
        }
     }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background: $blue !important;
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: $blue50;
    }

    .DayPicker-Caption {
      margin-bottom: 12px;
      & > div {
          font-weight: 500;
          font-size: inherit;
          text-align: center;
      }
    }

    .DayPicker-NavButton--interactionDisabled {
      display: inline-block;
      opacity: 0.8;
    }

    .DayPicker-NavButton {
        &--next, &--prev {
            width: 20px;
            height: 20px;
            background-size: 100%;
            outline: none;
            top: 11px;
        }

        &--prev {
            left: 16px;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_131_1865' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_131_1865)'%3E%3Cpath d='M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z' fill='%231C1B1F'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

        &--next {
            right: 16px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1050_3581' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1050_3581)'%3E%3Cpath d='M7.83329 15L6.66663 13.8333L10.5 10L6.66663 6.16667L7.83329 5L12.8333 10L7.83329 15Z' fill='%23191F25'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    .DayPicker-Weekday {
      padding: 10px;
      color: inherit;
      text-transform: capitalize;

        abbr {
            text-decoration: none !important;
            border-bottom: none !important;
            cursor: inherit !important;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 10px;
        }
    }
}

.is--inbound .DayPicker-Day {
  &--startDate {
    background: $blue !important;
    color: white !important;
  }

  &--active {
      color: $body;
      background: $blue50;
  }
}
