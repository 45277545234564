@import "resources.scss";
.tcp-form-group {
    display: flex;
    margin-bottom: 15px;
    width: 100%;

    &-label {
        padding-top: 5px;
        width: 150px;
        padding-right: 15px;
        flex-shrink: 0;

        &-big {
          width: 200px;
        }
    }

    &-info {
        @include font-small;

        color: $gray;
    }

    &-note {
        @include font-small;

        color: $gray;
        padding-left: 150px;
        max-width: 360px;
    }

    &-notice {
      @include font-medium;

      color: $gray;
      padding-left: 15px;
      padding-top: 5px;
    }

    &-multi {
      width: 100%;
      margin-top: 5px;

      .tcp-form-group-field {
          max-width: 100%;
      }
    }

    &-field {
        max-width: 360px;
        width: 100%;

        &-full {
            max-width: none;
        }

        .is--bottom-field {
          margin-top: 5px;
        }

        .is--big {
          height: auto;
        }

        .is--percentage {
          position: relative;

          input {
            padding-left: 35px;
          }

          &:before {
            content: "%";
            position: absolute;
            line-height: 36px;
            background: $lighter-gray;
            width: 25px;
            text-align: center;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            left: 1px;
            top: 1px;
            bottom: 1px;
          }
        }

        // TODO: refactor
        input, textarea {
            font-size: 14px;
            display: block;
            width: 100%;
            height: 38px;
            border-radius: $border-radius !important;
            background-color: #fff;
            border: 1px solid $light-gray;
            padding-left: 10px;
            background-color: #fff;
            background-image: none;
            background-clip: padding-box;

            &:focus {
                outline: none;
            }
        }

        .Select-input input {
          border: 0;
          padding: inherit;
          display: inline-block;
        }
    }

    &-button {
      margin-left: 20px;
      color: $blue;

      i {
        font-size: 14px;
        margin-right: 10px;
      }

      &:hover {
        color: $black;
      }
    }

    &-button-delete {
      color: $red;
    }

    &-separator {
        margin-bottom: 30px;
    }

    &-spaced {
      margin-left: -15px;
      flex-wrap: wrap;

      .tcp-form-group-field {
        padding-left: 15px;
        margin-bottom: 15px;
      }

      .form-error-message {
        padding-left: 15px;
        width: 100%;
      }
    }

    &-vertical {
        flex-direction: column;
        .tcp-form-group-label {
            width: auto;
        }
    }

    .form-error-message {
      @extend %is-font-medium;
      color: $red;
      margin-top: 5px;
    }

    &-cost-center {
      .tcp-form-group-field {
        max-width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      &-cost-center {
        flex-direction: column;
        width: 100%;

        .tcp-form-group {
          &-label {
            width: auto;
            margin-bottom: 10px;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      width: auto;

      &-cost-center, &-full {
        width: 100%;
      }

      &-6 {
        @include make-col(6);
      }
    }
}
