@import "resources.scss";
.collapse {
  transition: height 300ms 0s ease-in-out;

  .collapse-inner {
    opacity: 0;
    transition: opacity 200ms 0s ease-in-out;
  }

  &.is--open {
    .collapse-inner {
      opacity: 1;
      transition: opacity 200ms 300ms ease-in-out;
    }
  }
}

.collapse-auto-height {
  @include tn(opacity grid, 200ms 300ms, 0s 0s, ease-in-out ease-in-out);
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;

  .collapse-inner {
    opacity: 1;
    overflow: hidden;
  }

  &.is--open {
    @include tn(opacity grid, 200ms 300ms, 200ms 0s, ease-in-out ease-in-out);

    grid-template-rows: 1fr;
    opacity: 1;
  }
}
