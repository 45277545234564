@import "resources.scss";
.tcp-transport-segment-ticket-images {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  
  &-item {
    @include label;
  }

  &-transit {
    text-align: right;
    margin-bottom: 8px;
  }

  img {
    max-height: 24px;
  }

  @include media-breakpoint-up(sm) {
    img {
      max-height: 37px;
      max-width: 80px;
      margin-left: 15px;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }
  }
}
