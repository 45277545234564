
@mixin make-icon($icon:"") {
    font-family: 'TCP' !important;
    speak: none;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @if ($icon) {
      &:before {
        content: $icon;
        @content;
      }
    }
  }
