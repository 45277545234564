//  ================================================
//  Checkout
//  ================================================

.checkout {
  .list {
    &-select {
      //text-align: right;

    }

    &-badge {
      margin-left: 20px;
    }

    .route-tickets {
      text-align: right;
      font-size: 12px;
      line-height: 18px;
      opacity: .7;
    }

    @include media-breakpoint-up(sm) {
      &-select {
        //padding-right: 20px;
        strong {
          font-size: 18px;
        }
      }
    }
  }

  &-footer {
    padding-top: 20px;
    margin-bottom: 80px;

    .button {
      margin: auto;
    }
    // //TODO: used?
    // &-bordered {
    //   border-top: 1px solid $light-gray;
    // }

    // .legal-notice {
    //   padding-top: 20px;

    //   h2 {
    //     margin-bottom: 10px;
    //   }

    //   .link {
    //     text-decoration: underline;
    //   }
    // }
  }

  &-title {
    @extend %is-font-large;

    margin-top: 15px;
    margin-bottom: 10px;
  }

  &-info {
    text-align: left;
  }

  &-total {
    display: inline-block;
  }

  &-submit-btn {
    margin: 12px 0;
  }

  &-submit-btn-full {
    margin: 12px 0;
    display: inline-block;
    width: 100%;

    button {
      float: right;
    }
  }

  &-form-row {
    display: flex;
    flex-wrap: wrap;
  }

  // &-price {
  //   margin-top: 20px;
  //   display: flex;
  //   max-width: 100%;
  //   justify-content: space-between;
  //   //@include tablet {
  //   //    padding-top: 20px;
  //   //    clear: both;
  //   //}

  //   dt, dd {
  //     display: inline-block;
  //     //vertical-align: bottom;
  //   }

  //   dt {
  //     font-size: 14px;
  //     position: relative;

  //     &.small {
  //       font-size: 15px;
  //     }
  //   }

  //   dd {
  //     //text-align: right;
  //     font-size: 24px;
  //     font-weight: bold;
  //     //position: relative;

  //     &.small {
  //       text-align: right;
  //       font-size: 20px;
  //     }
  //   }

  //   &-info {
  //     font-weight: normal;
  //     font-size: 14px;
  //     margin-top: 5px;
  //     &:first-child {
  //       margin-top: 10px;
  //     }
  //   }
  // }

  &-complted-trip-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  &-box {
    h2 {
      padding: 20px;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 0;
    }

    &-content {
      padding: 20px 25px;
    }
  }

  &-passengers {
    .checkout-foote {
      text-align: left;
    }

    h3 {
      @extend %is-font-large;

      margin-bottom: 15px;
    }

    &-list {
      //TODO: ??
      @extend %reset;
    }

    &-item {
      border-bottom: 1px solid $light-gray;
      margin-bottom: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      h3 {
        flex: 1;
        flex-basis: 100%;
      }
    }

    &-edit {
      float: right;
    }

    &-traveler {
      float: left;
      margin-bottom: 0;

      label {
        display: block;
        color: $gray;
      }

      &-firstname {
        width: 18%;
      }

      &-lastname {
        width: 25%;
      }

      &-birthday {
        //width: 16%;

      }
    }
  }

  &-hotel-notes {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $gray-background;
    margin-top: -12px;
    margin-bottom: 20px;
    border: 1px solid $border-gray;
    border-top: 0;

    &-label {
      margin-bottom: 5px;
      font-weight: bold;
      flex-shrink: 1;
    }

    &-field {
      flex: 1;
      max-width: 0;

      //TODO: refactor with FormGroup
      textarea {
        font-family: $base-font;
        height: auto;
        padding: 10px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-title {
      margin-bottom: 20px;
      margin-top: 0;
    }

    &-footer {
      text-align: right;

      .button {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &-hotel-notes {
      flex-direction: row;
      margin-top: -12px;

      &-label {
        width: 200px;
        margin-right: 16px;
      }
    }

    &-passengers-item {
      justify-content: flex-start;
    }
  }
}

.icon-info__alignment-checkout {
  vertical-align: inherit;
  padding-right: 0.25rem;
}
