@import "resources.scss";
.lp-tariff-fare-terms {
  display: flex;
  flex-wrap: wrap;
  z-index: 0;

  &-link {
    @include caption;
    font-weight: 500;
    display: flex;
    align-items: center;

    i {
      font-size: 12px;
      margin-right: 4px;
    }

    &:hover {
      color: $blue;
    }
  }

  &-wrapper {
    margin-top: 20px;
    text-align: left;
  }
  .city-ticket-indicator {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 10px;

    &-tooltip {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        .is-large {
          width: calc(100vw - 200px);
        }
      }
    }
  }

  .train-seat-res {
    display: flex;
    justify-content: space-between;
    background-color: $lighter-gray;
    border-radius: 4px;
    width: 100%;
    padding: 10px;

    &-tooltip {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        .is-large {
          width: calc(100vw - 200px);
        }
      }
    }

    &-label {
      font-size: 12px;
      @extend %is-bold;
      padding: 0px !important;
    }

    &-value {
      align-self: center;
      white-space: nowrap;
      margin-right: 5px;

      &-not_available {
        display: flex;
        align-items: center;
        color: $red;
        i {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }

    .tcp-radio {
      align-self: center;
      margin-top: 0;

      > span {
        width: auto;
      }
      &-checked {
        .tcp-radio-switch-inner {
          background-color: $blue;
        }
      }
    }
  }


  .tcp-tooltip + .tcp-tooltip {
    margin-left: 10px;
  }

  .extra-icon {
    font-size: 15px;

    &-light {
      color: $gray;
    }
  }

  @include media-breakpoint-up(sm) {
    margin-top: 16px;
    z-index: auto;
  }
}
