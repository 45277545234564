@import "resources.scss";
.tcp-leg-waypoint {
    position: relative;
    height: 22px;

    &:before {
        @include pos(11px 0 0 0);
        content: "";
        background-color: $black;
        height: 2px;
    }


    &-icon {
        display: none;
        z-index: 1;
        position: relative;

        i {
            display: none;
        }
    }


    @include media-breakpoint-up(md) {
        position: relative;
        height: 24px;

        &:before {
            @include pos(11px 3px 0 27px);
        }
    
        &-icon {
            @include circle(6px);

            display: inline-block;
            height: 24px;
            width: 24px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            position: relative;
            border-radius: 50%;
            border: 1px solid $black;
            background-color: #fff;

            .icon-train {
                font-size: 13px;
            }

            [class^="icon-"] {
                display: inline-block;
                font-size: 14px;

                &:before {
                    margin: 0;
                    vertical-align: baseline;
                }
            }

            .icon-plane {
                font-size: 15px;
                line-height: 15px;
            }
        }
    }
}

.tcp-leg-waypoint-vertical {
    display: none;

   

    @include media-breakpoint-up(md) {
        display: block;
        position: static;
        padding-top: 24px;

        &:before {
            background-color: $black;
            height: auto;
            width: 2px;
            position: absolute;
            top: 54px;
            bottom: 10px;
            left: 31px;
        }
        
        .tcp-leg-waypoint-icon {
            height: 24px;
            width: 24px;
            line-height: 24px;
      
            [class^="icon-"] {
              display: inline-block;
              font-size: 14px;
            }
        }
    }
    &-item-public {
        color: $light-gray;
        &:before {
            background-color: $light-gray;
        }
        &:after {
            border-color: $light-gray transparent transparent transparent;
        }
        &-icon {
            color: $light-gray;
            border-color: $light-gray;
        }

        .tcp-leg-last & {
            padding-top: calc(24px + 43px);
            &:before {
                top: calc(54px + 43px);
            }
        }
    }
}
