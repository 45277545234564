/* todo: refactore
  only used on register page
*/
%grid-padding {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @mixin make-col($size, $cols: 12) {
    flex: 0 0 percentage($size / 12);
    max-width: percentage($size / 12);
  }
  
  @mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left:  -15px;
  }
  
  @mixin make-percent-col($percent) {
    flex: 0 0 percentage($percent / 100);
    max-width: percentage($percent / 100);
  }
  