@import "resources.scss";
.lp-show-more {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 24px;
    margin-bottom: 60px;
  }

  &-label {
    font-weight: 500;
    margin-bottom: 16px;
  }

  &-btn {
    font-weight: 500;
    color: $blue;
    display: flex;
    align-items: center;

    i {
      @include tn(transform);
      margin-left: 10px;
    }

    &:hover {
      color: $black;
      i {
        transform: translateY(4px);
      }
    }
  }
}
