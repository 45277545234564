@import "resources.scss";
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tcp-spinner {
  display: block;
  text-align: center;
  padding: 15px 0;

  &-button {
    padding: 0;
    height: 15px
  }

  &-circle {
    animation: spin 1.1s infinite linear;
    border-radius: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: 100;
    font-size: 10px;
    border-top: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    border-left: 1px solid $blue;
    transform: translateZ(0);
    &-button {
      width: 15px;
      height: 15px;
      margin-right: 15px;
      border-left: 1px solid $white;
    }

    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;

      &-button {
        width: 15px;
        height: 15px;
      }
    }
  }

  &.is--small {
    @include media-breakpoint-up(sm) {
      .tcp-spinner {
        &-circle {
          height: 24px;
          width: 24px;
          border-width: 2px;
        }
      }
    }
  }
}
