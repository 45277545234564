$icomoon-font-path: "../assets/fonts" !default;

$icon-help_center: "\e961";
$icon-other-refund1: "\e982";
$icon-shuttle-service: "\e980";
$icon-hotel_kontingent: "\e981";
$icon-hand-luggage_NA: "\e974";
$icon-more_seat_space_NA-path1: "\e975";
$icon-more_seat_space_NA-path2: "\e976";
$icon-more_seat_space_NA-path3: "\e977";
$icon-more_seat_space_NA-path4: "\e978";
$icon-more_seat_space_NA-path5: "\e979";
$icon-more_seat_space: "\e97a";
$icon-plane-multi-path1: "\e97b";
$icon-plane-multi-path2: "\e97c";
$icon-preferred_seating_NA: "\e97d";
$icon-preferred_seating: "\e97e";
$icon-work_NA: "\e97f";
$icon-airbnb: "\e900";
$icon-error: "\e000";
$icon-error_outline: "\e001";
$icon-warning: "\e002";
$icon-add_alert: "\e003";
$icon-album: "\e019";
$icon-av_timer: "\e01b";
$icon-closed_caption: "\e01c";
$icon-equalizer: "\e01d";
$icon-explicit: "\e01e";
$icon-fast_forward: "\e01f";
$icon-fast_rewind: "\e020";
$icon-games: "\e021";
$icon-hearing: "\e023";
$icon-high_quality: "\e024";
$icon-mic: "\e029";
$icon-mic_none: "\e02a";
$icon-mic_off: "\e02b";
$icon-library_books: "\e02f";
$icon-library_music: "\e030";
$icon-new_releases: "\e031";
$icon-not_interested: "\e033";
$icon-pause: "\e034";
$icon-pause_circle_filled: "\e035";
$icon-pause_circle_outline: "\e036";
$icon-play_arrow: "\e037";
$icon-play_circle_filled: "\e038";
$icon-play_circle_outline: "\e039";
$icon-playlist_add: "\e03b";
$icon-queue: "\e03c";
$icon-queue_music: "\e03d";
$icon-radio: "\e03e";
$icon-recent_actors: "\e03f";
$icon-repeat: "\e040";
$icon-repeat_one: "\e041";
$icon-replay: "\e042";
$icon-shuffle: "\e043";
$icon-skip_next: "\e044";
$icon-skip_previous: "\e045";
$icon-snooze: "\e046";
$icon-stop: "\e047";
$icon-subtitles: "\e048";
$icon-surround_sound: "\e049";
$icon-video_library: "\e04a";
$icon-videocam: "\e04b";
$icon-videocam_off: "\e04c";
$icon-volume_down: "\e04d";
$icon-volume_mute: "\e04e";
$icon-volume_off: "\e04f";
$icon-volume_up: "\e050";
$icon-web: "\e051";
$icon-hd: "\e052";
$icon-sort_by_alpha: "\e053";
$icon-airplay: "\e055";
$icon-forward_10: "\e056";
$icon-forward_30: "\e057";
$icon-forward_5: "\e058";
$icon-replay_10: "\e059";
$icon-replay_30: "\e05a";
$icon-replay_5: "\e05b";
$icon-add_to_queue: "\e05c";
$icon-fiber_dvr: "\e05d";
$icon-fiber_new: "\e05e";
$icon-playlist_play: "\e05f";
$icon-art_track: "\e060";
$icon-fiber_manual_record: "\e061";
$icon-fiber_smart_record: "\e062";
$icon-music_video: "\e063";
$icon-subscriptions: "\e064";
$icon-playlist_add_check: "\e065";
$icon-queue_play_next: "\e066";
$icon-remove_from_queue: "\e067";
$icon-slow_motion_video: "\e068";
$icon-web_asset: "\e069";
$icon-fiber_pin: "\e06a";
$icon-branding_watermark: "\e06b";
$icon-call_to_action: "\e06c";
$icon-featured_play_list: "\e06d";
$icon-featured_video: "\e06e";
$icon-note: "\e06f";
$icon-video_call: "\e070";
$icon-video_label: "\e071";
$icon-call_end: "\e0b1";
$icon-call_made: "\e0b2";
$icon-call_missed: "\e0b4";
$icon-call_received: "\e0b5";
$icon-call_split: "\e0b6";
$icon-chat: "\e0b7";
$icon-clear_all: "\e0b8";
$icon-comment: "\e0b9";
$icon-contacts: "\e0ba";
$icon-dialer_sip: "\e0bb";
$icon-dialpad: "\e0bc";
$icon-import_export: "\e0c3";
$icon-invert_colors_off: "\e0c4";
$icon-live_help: "\e0c6";
$icon-location_off: "\e0c7";
$icon-message: "\e0c9";
$icon-chat_bubble: "\e0ca";
$icon-chat_bubble_outline: "\e0cb";
$icon-phone: "\e0cd";
$icon-portable_wifi_off: "\e0ce";
$icon-contact_phone: "\e0cf";
$icon-contact_mail: "\e0d0";
$icon-ring_volume: "\e0d1";
$icon-speaker_phone: "\e0d2";
$icon-stay_primary_landscape: "\e0d5";
$icon-stay_primary_portrait: "\e0d6";
$icon-swap_calls: "\e0d7";
$icon-textsms: "\e0d8";
$icon-voicemail: "\e0d9";
$icon-vpn_key: "\e0da";
$icon-phonelink_erase: "\e0db";
$icon-phonelink_lock: "\e0dc";
$icon-phonelink_ring: "\e0dd";
$icon-phonelink_setup: "\e0de";
$icon-present_to_all: "\e0df";
$icon-import_contacts: "\e0e0";
$icon-mail_outline: "\e0e1";
$icon-screen_share: "\e0e2";
$icon-stop_screen_share: "\e0e3";
$icon-call_missed_outgoing: "\e0e4";
$icon-rss_feed: "\e0e5";
$icon-add2: "\e145";
$icon-add_box: "\e146";
$icon-add_circle: "\e147";
$icon-archive: "\e149";
$icon-backspace: "\e14a";
$icon-block: "\e14b";
$icon-content_copy: "\e14d";
$icon-content_cut: "\e14e";
$icon-content_paste: "\e14f";
$icon-drafts: "\e151";
$icon-filter_list: "\e152";
$icon-flag: "\e153";
$icon-forward: "\e154";
$icon-gesture: "\e155";
$icon-inbox: "\e156";
$icon-link: "\e157";
$icon-markunread: "\e159";
$icon-redo: "\e15a";
$icon-remove: "\e15b";
$icon-remove_circle: "\e15c";
$icon-remove_circle_outline2: "\e15d";
$icon-reply: "\e15e";
$icon-reply_all: "\e15f";
$icon-report: "\e160";
$icon-save: "\e161";
$icon-select_all: "\e162";
$icon-send: "\e163";
$icon-sort2: "\e164";
$icon-text_format: "\e165";
$icon-undo: "\e166";
$icon-font_download: "\e167";
$icon-move_to_inbox: "\e168";
$icon-unarchive: "\e169";
$icon-next_week: "\e16a";
$icon-weekend: "\e16b";
$icon-delete_sweep: "\e16c";
$icon-low_priority: "\e16d";
$icon-access_alarms: "\e191";
$icon-airplanemode_inactive: "\e194";
$icon-battery_alert: "\e19c";
$icon-battery_charging_full: "\e1a3";
$icon-battery_std: "\e1a5";
$icon-battery_unknown: "\e1a6";
$icon-bluetooth: "\e1a7";
$icon-bluetooth_connected: "\e1a8";
$icon-bluetooth_disabled: "\e1a9";
$icon-bluetooth_searching: "\e1aa";
$icon-brightness_auto: "\e1ab";
$icon-brightness_high: "\e1ac";
$icon-brightness_low: "\e1ad";
$icon-brightness_medium: "\e1ae";
$icon-data_usage: "\e1af";
$icon-developer_mode: "\e1b0";
$icon-dvr: "\e1b2";
$icon-location_disabled: "\e1b6";
$icon-location_searching: "\e1b7";
$icon-graphic_eq: "\e1b8";
$icon-network_cell: "\e1b9";
$icon-network_wifi: "\e1ba";
$icon-nfc: "\e1bb";
$icon-wallpaper: "\e1bc";
$icon-widgets: "\e1bd";
$icon-screen_lock_landscape: "\e1be";
$icon-screen_lock_portrait: "\e1bf";
$icon-screen_lock_rotation: "\e1c0";
$icon-screen_rotation: "\e1c1";
$icon-sd_storage: "\e1c2";
$icon-settings_system_daydream: "\e1c3";
$icon-signal_cellular_4_bar: "\e1c8";
$icon-signal_cellular_connected_no_internet_4_bar: "\e1cd";
$icon-signal_cellular_no_sim: "\e1ce";
$icon-signal_cellular_null: "\e1cf";
$icon-signal_cellular_off: "\e1d0";
$icon-signal_wifi_4_bar: "\e1d8";
$icon-signal_wifi_4_bar_lock: "\e1d9";
$icon-signal_wifi_off: "\e1da";
$icon-storage: "\e1db";
$icon-usb: "\e1e0";
$icon-wifi_lock: "\e1e1";
$icon-wifi_tethering: "\e1e2";
$icon-attach_file: "\e226";
$icon-attach_money: "\e227";
$icon-border_all: "\e228";
$icon-border_bottom: "\e229";
$icon-border_clear: "\e22a";
$icon-border_color: "\e22b";
$icon-border_horizontal: "\e22c";
$icon-border_inner: "\e22d";
$icon-border_left: "\e22e";
$icon-border_outer: "\e22f";
$icon-border_right: "\e230";
$icon-border_style: "\e231";
$icon-border_top: "\e232";
$icon-border_vertical: "\e233";
$icon-format_align_center: "\e234";
$icon-format_align_justify: "\e235";
$icon-format_align_left: "\e236";
$icon-format_align_right: "\e237";
$icon-format_bold: "\e238";
$icon-format_clear: "\e239";
$icon-format_color_fill: "\e23a";
$icon-format_color_reset: "\e23b";
$icon-format_color_text: "\e23c";
$icon-format_indent_decrease: "\e23d";
$icon-format_indent_increase: "\e23e";
$icon-format_italic: "\e23f";
$icon-format_line_spacing: "\e240";
$icon-format_list_bulleted: "\e241";
$icon-format_list_numbered: "\e242";
$icon-format_paint: "\e243";
$icon-format_quote: "\e244";
$icon-format_size: "\e245";
$icon-format_strikethrough: "\e246";
$icon-format_textdirection_l_to_r: "\e247";
$icon-format_textdirection_r_to_l: "\e248";
$icon-format_underlined: "\e249";
$icon-functions: "\e24a";
$icon-insert_comment: "\e24c";
$icon-insert_drive_file: "\e24d";
$icon-insert_invitation: "\e24f";
$icon-merge_type: "\e252";
$icon-mode_comment: "\e253";
$icon-mode_edit: "\e254";
$icon-publish: "\e255";
$icon-space_bar: "\e256";
$icon-strikethrough_s: "\e257";
$icon-vertical_align_bottom: "\e258";
$icon-vertical_align_center: "\e259";
$icon-vertical_align_top: "\e25a";
$icon-wrap_text: "\e25b";
$icon-money_off: "\e25c";
$icon-drag_handle: "\e25d";
$icon-format_shapes: "\e25e";
$icon-highlight: "\e25f";
$icon-linear_scale: "\e260";
$icon-short_text: "\e261";
$icon-text_fields: "\e262";
$icon-monetization_on: "\e263";
$icon-title: "\e264";
$icon-attachment: "\e2bc";
$icon-cloud_circle: "\e2be";
$icon-cloud_done: "\e2bf";
$icon-cloud_download: "\e2c0";
$icon-cloud_off: "\e2c1";
$icon-cloud_queue: "\e2c2";
$icon-cloud_upload: "\e2c3";
$icon-file_upload: "\e2c6";
$icon-folder: "\e2c7";
$icon-folder_open: "\e2c8";
$icon-folder_shared: "\e2c9";
$icon-create_new_folder: "\e2cc";
$icon-cast: "\e307";
$icon-cast_connected: "\e308";
$icon-desktop_mac: "\e30b";
$icon-desktop_windows: "\e30c";
$icon-developer_board: "\e30d";
$icon-dock: "\e30e";
$icon-headset: "\e310";
$icon-headset_mic: "\e311";
$icon-keyboard: "\e312";
$icon-keyboard_arrow_down: "\e313";
$icon-keyboard_arrow_left: "\e314";
$icon-keyboard_arrow_right: "\e315";
$icon-keyboard_arrow_up: "\e316";
$icon-keyboard_backspace: "\e317";
$icon-keyboard_capslock: "\e318";
$icon-keyboard_hide: "\e31a";
$icon-keyboard_return: "\e31b";
$icon-keyboard_tab: "\e31c";
$icon-keyboard_voice: "\e31d";
$icon-laptop: "\e31e";
$icon-laptop_chromebook: "\e31f";
$icon-laptop_mac: "\e320";
$icon-laptop_windows: "\e321";
$icon-memory: "\e322";
$icon-mouse: "\e323";
$icon-phone_android: "\e324";
$icon-phone_iphone: "\e325";
$icon-phonelink: "\e326";
$icon-phonelink_off: "\e327";
$icon-router: "\e328";
$icon-scanner: "\e329";
$icon-security: "\e32a";
$icon-sim_card: "\e32b";
$icon-speaker: "\e32d";
$icon-speaker_group: "\e32e";
$icon-tablet: "\e32f";
$icon-tablet_android: "\e330";
$icon-tablet_mac: "\e331";
$icon-toys: "\e332";
$icon-tv2: "\e333";
$icon-watch: "\e334";
$icon-device_hub: "\e335";
$icon-power_input: "\e336";
$icon-devices_other: "\e337";
$icon-videogame_asset: "\e338";
$icon-adjust: "\e39e";
$icon-assistant: "\e39f";
$icon-audiotrack: "\e3a1";
$icon-blur_circular: "\e3a2";
$icon-blur_linear: "\e3a3";
$icon-blur_off: "\e3a4";
$icon-blur_on: "\e3a5";
$icon-brightness_1: "\e3a6";
$icon-brightness_2: "\e3a7";
$icon-brightness_3: "\e3a8";
$icon-brightness_4: "\e3a9";
$icon-broken_image: "\e3ad";
$icon-brush: "\e3ae";
$icon-camera: "\e3af";
$icon-camera_front: "\e3b1";
$icon-camera_rear: "\e3b2";
$icon-camera_roll: "\e3b3";
$icon-center_focus_strong: "\e3b4";
$icon-center_focus_weak: "\e3b5";
$icon-colorize: "\e3b8";
$icon-compare: "\e3b9";
$icon-control_point: "\e3ba";
$icon-control_point_duplicate: "\e3bb";
$icon-crop_16_9: "\e3bc";
$icon-crop_3_2: "\e3bd";
$icon-crop: "\e3be";
$icon-crop_7_5: "\e3c0";
$icon-crop_din: "\e3c1";
$icon-crop_free: "\e3c2";
$icon-crop_landscape: "\e3c3";
$icon-crop_original: "\e3c4";
$icon-crop_portrait: "\e3c5";
$icon-crop_square: "\e3c6";
$icon-dehaze: "\e3c7";
$icon-details: "\e3c8";
$icon-exposure: "\e3ca";
$icon-exposure_neg_1: "\e3cb";
$icon-exposure_neg_2: "\e3cc";
$icon-exposure_plus_1: "\e3cd";
$icon-exposure_plus_2: "\e3ce";
$icon-exposure_zero: "\e3cf";
$icon-filter_1: "\e3d0";
$icon-filter_2: "\e3d1";
$icon-filter_3: "\e3d2";
$icon-filter2: "\e3d3";
$icon-filter_4: "\e3d4";
$icon-filter_5: "\e3d5";
$icon-filter_6: "\e3d6";
$icon-filter_7: "\e3d7";
$icon-filter_8: "\e3d8";
$icon-filter_9: "\e3d9";
$icon-filter_9_plus: "\e3da";
$icon-filter_b_and_w: "\e3db";
$icon-filter_center_focus: "\e3dc";
$icon-filter_drama: "\e3dd";
$icon-filter_frames: "\e3de";
$icon-filter_none: "\e3e0";
$icon-filter_tilt_shift: "\e3e2";
$icon-filter_vintage: "\e3e3";
$icon-flare: "\e3e4";
$icon-flash_auto: "\e3e5";
$icon-flash_off: "\e3e6";
$icon-flash_on: "\e3e7";
$icon-flip: "\e3e8";
$icon-gradient: "\e3e9";
$icon-grain: "\e3ea";
$icon-grid_off: "\e3eb";
$icon-grid_on: "\e3ec";
$icon-hdr_off: "\e3ed";
$icon-hdr_on: "\e3ee";
$icon-hdr_strong: "\e3f1";
$icon-hdr_weak: "\e3f2";
$icon-healing: "\e3f3";
$icon-image_aspect_ratio: "\e3f5";
$icon-iso: "\e3f6";
$icon-leak_add: "\e3f8";
$icon-leak_remove: "\e3f9";
$icon-lens: "\e3fa";
$icon-looks_3: "\e3fb";
$icon-looks: "\e3fc";
$icon-looks_4: "\e3fd";
$icon-looks_5: "\e3fe";
$icon-looks_6: "\e3ff";
$icon-looks_one: "\e400";
$icon-looks_two: "\e401";
$icon-loupe: "\e402";
$icon-monochrome_photos: "\e403";
$icon-movie_creation: "\e404";
$icon-music_note: "\e405";
$icon-nature: "\e406";
$icon-nature_people: "\e407";
$icon-navigate_before: "\e408";
$icon-navigate_next: "\e409";
$icon-palette: "\e40a";
$icon-panorama: "\e40b";
$icon-panorama_horizontal: "\e40d";
$icon-panorama_vertical: "\e40e";
$icon-panorama_wide_angle: "\e40f";
$icon-photo: "\e410";
$icon-photo_album: "\e411";
$icon-photo_camera: "\e412";
$icon-photo_library: "\e413";
$icon-picture_as_pdf: "\e415";
$icon-portrait: "\e416";
$icon-rotate_90_degrees_ccw: "\e418";
$icon-rotate_left: "\e419";
$icon-rotate_right: "\e41a";
$icon-slideshow: "\e41b";
$icon-straighten: "\e41c";
$icon-style: "\e41d";
$icon-switch_camera: "\e41e";
$icon-switch_video: "\e41f";
$icon-tag_faces: "\e420";
$icon-texture: "\e421";
$icon-timelapse: "\e422";
$icon-timer_10: "\e423";
$icon-timer_3: "\e424";
$icon-timer: "\e425";
$icon-timer_off: "\e426";
$icon-tonality: "\e427";
$icon-transform: "\e428";
$icon-tune: "\e429";
$icon-view_comfy: "\e42a";
$icon-view_compact: "\e42b";
$icon-wb_auto: "\e42c";
$icon-wb_cloudy: "\e42d";
$icon-wb_incandescent: "\e42e";
$icon-wb_sunny: "\e430";
$icon-collections_bookmark: "\e431";
$icon-photo_size_select_actual: "\e432";
$icon-photo_size_select_large: "\e433";
$icon-photo_size_select_small: "\e434";
$icon-vignette: "\e435";
$icon-wb_iridescent: "\e436";
$icon-crop_rotate: "\e437";
$icon-linked_camera: "\e438";
$icon-add_a_photo: "\e439";
$icon-movie_filter: "\e43a";
$icon-photo_filter: "\e43b";
$icon-burst_mode: "\e43c";
$icon-beenhere: "\e52d";
$icon-directions: "\e52e";
$icon-directions_bike2: "\e52f";
$icon-directions_bus: "\e530";
$icon-directions_car: "\e531";
$icon-directions_boat: "\e532";
$icon-directions_railway: "\e534";
$icon-directions_transit: "\e535";
$icon-directions_walk: "\e536";
$icon-flight: "\e539";
$icon-layers: "\e53b";
$icon-layers_clear: "\e53c";
$icon-local_airport: "\e53d";
$icon-local_atm: "\e53e";
$icon-local_bar: "\e540";
$icon-local_cafe: "\e541";
$icon-local_car_wash: "\e542";
$icon-local_convenience_store: "\e543";
$icon-local_drink: "\e544";
$icon-local_florist: "\e545";
$icon-local_gas_station: "\e546";
$icon-local_hospital: "\e548";
$icon-local_hotel: "\e549";
$icon-local_laundry_service: "\e54a";
$icon-local_library: "\e54b";
$icon-local_mall: "\e54c";
$icon-local_offer: "\e54e";
$icon-local_parking2: "\e54f";
$icon-local_pharmacy: "\e550";
$icon-local_pizza: "\e552";
$icon-local_play: "\e553";
$icon-local_shipping: "\e558";
$icon-local_taxi: "\e559";
$icon-person_pin: "\e55a";
$icon-map3: "\e55b";
$icon-my_location: "\e55c";
$icon-navigation: "\e55d";
$icon-pin_drop: "\e55e";
$icon-rate_review: "\e560";
$icon-restaurant_menu: "\e561";
$icon-satellite: "\e562";
$icon-store_mall_directory: "\e563";
$icon-terrain: "\e564";
$icon-traffic: "\e565";
$icon-directions_run: "\e566";
$icon-add_location: "\e567";
$icon-edit_location: "\e568";
$icon-near_me: "\e569";
$icon-person_pin_circle: "\e56a";
$icon-zoom_out_map: "\e56b";
$icon-restaurant: "\e56c";
$icon-ev_station: "\e56d";
$icon-streetview: "\e56e";
$icon-subway: "\e56f";
$icon-train2: "\e570";
$icon-tram: "\e571";
$icon-transfer_within_a_station: "\e572";
$icon-apps: "\e5c3";
$icon-arrow_back: "\e5c4";
$icon-arrow_drop_down: "\e5c5";
$icon-arrow_drop_down_circle: "\e5c6";
$icon-arrow_drop_up: "\e5c7";
$icon-arrow_forward: "\e5c8";
$icon-cancel: "\e5c9";
$icon-check2: "\e5ca";
$icon-close2: "\e5cd";
$icon-expand_less: "\e5ce";
$icon-expand_more: "\e5cf";
$icon-fullscreen: "\e5d0";
$icon-fullscreen_exit: "\e5d1";
$icon-menu: "\e5d2";
$icon-more_horiz: "\e5d3";
$icon-more_vert: "\e5d4";
$icon-refresh: "\e5d5";
$icon-unfold_less: "\e5d6";
$icon-unfold_more: "\e5d7";
$icon-arrow_upward: "\e5d8";
$icon-subdirectory_arrow_left: "\e5d9";
$icon-subdirectory_arrow_right: "\e5da";
$icon-arrow_downward: "\e5db";
$icon-first_page: "\e5dc";
$icon-last_page: "\e5dd";
$icon-adb: "\e60e";
$icon-disc_full: "\e610";
$icon-do_not_disturb_alt: "\e611";
$icon-event_available: "\e614";
$icon-event_busy: "\e615";
$icon-event_note: "\e616";
$icon-folder_special: "\e617";
$icon-mms: "\e618";
$icon-more: "\e619";
$icon-network_locked: "\e61a";
$icon-phone_bluetooth_speaker: "\e61b";
$icon-phone_forwarded: "\e61c";
$icon-phone_in_talk: "\e61d";
$icon-phone_locked: "\e61e";
$icon-phone_missed: "\e61f";
$icon-phone_paused: "\e620";
$icon-sim_card_alert: "\e624";
$icon-sms_failed: "\e626";
$icon-sync: "\e627";
$icon-sync_disabled: "\e628";
$icon-sync_problem: "\e629";
$icon-system_update: "\e62a";
$icon-tap_and_play: "\e62b";
$icon-time_to_leave: "\e62c";
$icon-vibration: "\e62d";
$icon-voice_chat: "\e62e";
$icon-vpn_lock: "\e62f";
$icon-airline_seat_flat: "\e630";
$icon-airline_seat_flat_angled: "\e631";
$icon-airline_seat_individual_suite: "\e632";
$icon-airline_seat_legroom_extra: "\e633";
$icon-airline_seat_legroom_normal: "\e634";
$icon-airline_seat_legroom_reduced: "\e635";
$icon-airline_seat_recline_extra: "\e636";
$icon-airline_seat_recline_normal: "\e637";
$icon-confirmation_number: "\e638";
$icon-live_tv: "\e639";
$icon-ondemand_video: "\e63a";
$icon-personal_video: "\e63b";
$icon-power: "\e63c";
$icon-wc: "\e63d";
$icon-wifi2: "\e63e";
$icon-enhanced_encryption: "\e63f";
$icon-network_check: "\e640";
$icon-no_encryption: "\e641";
$icon-rv_hookup: "\e642";
$icon-do_not_disturb_off: "\e643";
$icon-priority_high: "\e645";
$icon-pie_chart: "\e6c4";
$icon-pie_chart_outlined: "\e6c5";
$icon-bubble_chart: "\e6dd";
$icon-multiline_chart: "\e6df";
$icon-show_chart: "\e6e1";
$icon-cake: "\e7e9";
$icon-domain: "\e7ee";
$icon-group_add: "\e7f0";
$icon-location_city: "\e7f1";
$icon-mood_bad: "\e7f3";
$icon-notifications: "\e7f4";
$icon-notifications_none: "\e7f5";
$icon-notifications_off: "\e7f6";
$icon-notifications_active: "\e7f7";
$icon-notifications_paused: "\e7f8";
$icon-pages: "\e7f9";
$icon-party_mode: "\e7fa";
$icon-people: "\e7fb";
$icon-people_outline: "\e7fc";
$icon-person: "\e7fd";
$icon-person_add: "\e7fe";
$icon-person_outline: "\e7ff";
$icon-plus_one: "\e800";
$icon-poll: "\e801";
$icon-public: "\e80b";
$icon-school: "\e80c";
$icon-share2: "\e80d";
$icon-whatshot: "\e80e";
$icon-sentiment_dissatisfied: "\e811";
$icon-sentiment_neutral: "\e812";
$icon-sentiment_satisfied: "\e813";
$icon-sentiment_very_dissatisfied: "\e814";
$icon-sentiment_very_satisfied: "\e815";
$icon-check_box2: "\e834";
$icon-check_box_outline_blank2: "\e835";
$icon-radio_button_unchecked2: "\e836";
$icon-radio_button_checked2: "\e837";
$icon-star2: "\e838";
$icon-star_half2: "\e839";
$icon-star_border2: "\e83a";
$icon-3d_rotation: "\e84d";
$icon-accessibility: "\e84e";
$icon-account_balance: "\e84f";
$icon-account_balance_wallet: "\e850";
$icon-account_box: "\e851";
$icon-account_circle: "\e853";
$icon-add_shopping_cart: "\e854";
$icon-alarm: "\e855";
$icon-alarm_add: "\e856";
$icon-alarm_off: "\e857";
$icon-alarm_on: "\e858";
$icon-android: "\e859";
$icon-announcement: "\e85a";
$icon-aspect_ratio: "\e85b";
$icon-assignment: "\e85d";
$icon-assignment_ind: "\e85e";
$icon-assignment_late: "\e85f";
$icon-assignment_return: "\e860";
$icon-assignment_returned: "\e861";
$icon-assignment_turned_in: "\e862";
$icon-autorenew: "\e863";
$icon-bug_report: "\e868";
$icon-build: "\e869";
$icon-cached: "\e86a";
$icon-change_history: "\e86b";
$icon-check_circle: "\e86c";
$icon-chrome_reader_mode: "\e86d";
$icon-class: "\e86e";
$icon-code: "\e86f";
$icon-dashboard: "\e871";
$icon-delete: "\e872";
$icon-description: "\e873";
$icon-dns: "\e875";
$icon-done: "\e876";
$icon-done_all: "\e877";
$icon-exit_to_app: "\e879";
$icon-explore: "\e87a";
$icon-extension: "\e87b";
$icon-face: "\e87c";
$icon-favorite2: "\e87d";
$icon-favorite_border: "\e87e";
$icon-find_in_page: "\e880";
$icon-find_replace: "\e881";
$icon-flip_to_back: "\e882";
$icon-flip_to_front: "\e883";
$icon-get_app: "\e884";
$icon-group_work: "\e886";
$icon-help: "\e887";
$icon-highlight_off2: "\e888";
$icon-home2: "\e88a";
$icon-hourglass_empty: "\e88b";
$icon-hourglass_full: "\e88c";
$icon-info2: "\e88e";
$icon-info_outline: "\e88f";
$icon-input: "\e890";
$icon-invert_colors: "\e891";
$icon-label: "\e892";
$icon-label_outline: "\e893";
$icon-language: "\e894";
$icon-list: "\e896";
$icon-lock2: "\e897";
$icon-lock_open: "\e898";
$icon-lock_outline: "\e899";
$icon-loyalty: "\e89a";
$icon-markunread_mailbox: "\e89b";
$icon-note_add: "\e89c";
$icon-open_in_browser: "\e89d";
$icon-open_in_new: "\e89e";
$icon-open_with: "\e89f";
$icon-pageview: "\e8a0";
$icon-payment: "\e8a1";
$icon-perm_camera_mic: "\e8a2";
$icon-perm_contact_calendar: "\e8a3";
$icon-perm_data_setting: "\e8a4";
$icon-perm_device_information: "\e8a5";
$icon-perm_media: "\e8a7";
$icon-perm_phone_msg: "\e8a8";
$icon-perm_scan_wifi: "\e8a9";
$icon-picture_in_picture: "\e8aa";
$icon-polymer: "\e8ab";
$icon-power_settings_new: "\e8ac";
$icon-print: "\e8ad";
$icon-question_answer: "\e8af";
$icon-receipt: "\e8b0";
$icon-redeem: "\e8b1";
$icon-restore: "\e8b3";
$icon-room: "\e8b4";
$icon-schedule: "\e8b5";
$icon-search2: "\e8b6";
$icon-settings: "\e8b8";
$icon-settings_applications: "\e8b9";
$icon-settings_backup_restore: "\e8ba";
$icon-settings_bluetooth: "\e8bb";
$icon-settings_cell: "\e8bc";
$icon-settings_brightness: "\e8bd";
$icon-settings_ethernet: "\e8be";
$icon-settings_input_antenna: "\e8bf";
$icon-settings_input_composite: "\e8c1";
$icon-settings_input_hdmi: "\e8c2";
$icon-settings_input_svideo: "\e8c3";
$icon-settings_overscan: "\e8c4";
$icon-settings_phone: "\e8c5";
$icon-settings_power: "\e8c6";
$icon-settings_remote: "\e8c7";
$icon-settings_voice: "\e8c8";
$icon-shop: "\e8c9";
$icon-shop_two: "\e8ca";
$icon-shopping_basket: "\e8cb";
$icon-shopping_cart: "\e8cc";
$icon-speaker_notes: "\e8cd";
$icon-spellcheck: "\e8ce";
$icon-stars: "\e8d0";
$icon-subject: "\e8d2";
$icon-supervisor_account: "\e8d3";
$icon-swap_horiz: "\e8d4";
$icon-swap_vert: "\e8d5";
$icon-swap_vertical_circle: "\e8d6";
$icon-system_update_alt: "\e8d7";
$icon-tab: "\e8d8";
$icon-tab_unselected: "\e8d9";
$icon-theaters: "\e8da";
$icon-thumb_down: "\e8db";
$icon-thumb_up: "\e8dc";
$icon-thumbs_up_down: "\e8dd";
$icon-toc: "\e8de";
$icon-today: "\e8df";
$icon-toll: "\e8e0";
$icon-track_changes: "\e8e1";
$icon-translate: "\e8e2";
$icon-trending_down: "\e8e3";
$icon-trending_flat: "\e8e4";
$icon-trending_up: "\e8e5";
$icon-turned_in: "\e8e6";
$icon-turned_in_not: "\e8e7";
$icon-verified_user: "\e8e8";
$icon-view_agenda: "\e8e9";
$icon-view_array: "\e8ea";
$icon-view_carousel: "\e8eb";
$icon-view_column: "\e8ec";
$icon-view_day: "\e8ed";
$icon-view_headline: "\e8ee";
$icon-view_list: "\e8ef";
$icon-view_module: "\e8f0";
$icon-view_quilt: "\e8f1";
$icon-view_stream: "\e8f2";
$icon-view_week: "\e8f3";
$icon-visibility: "\e8f4";
$icon-visibility_off: "\e8f5";
$icon-card_membership: "\e8f7";
$icon-card_travel: "\e8f8";
$icon-work2: "\e8f9";
$icon-youtube_searched_for: "\e8fa";
$icon-eject: "\e8fb";
$icon-camera_enhance: "\e8fc";
$icon-help_outline: "\e8fd";
$icon-reorder: "\e8fe";
$icon-zoom_in: "\e8ff";
$icon-check: "\e901";
$icon-back: "\e902";
$icon-close: "\e903";
$icon-bookings: "\e904";
$icon-add_calendar: "\e905";
$icon-info: "\e906";
$icon-calendar: "\e907";
$icon-public_transport: "\e908";
$icon-add_user: "\e909";
$icon-add: "\e90a";
$icon-bus: "\e90b";
$icon-cheapest: "\e90c";
$icon-company: "\e90d";
$icon-disabled: "\e90e";
$icon-distance: "\e90f";
$icon-download: "\e910";
$icon-duration: "\e911";
$icon-edit: "\e912";
$icon-external_link: "\e913";
$icon-fastest: "\e914";
$icon-favorite: "\e915";
$icon-home: "\e916";
$icon-male-female: "\e917";
$icon-map2: "\e918";
$icon-no_shuttle: "\e919";
$icon-passengers: "\e91a";
$icon-plane: "\e91b";
$icon-public-taxi: "\e91c";
$icon-public-train: "\e91d";
$icon-public-tram: "\e91e";
$icon-refund2: "\e91f";
$icon-rental_car: "\e920";
$icon-rooms: "\e921";
$icon-share: "\e922";
$icon-sort: "\e923";
$icon-stops: "\e924";
$icon-train: "\e925";
$icon-transfer: "\e926";
$icon-upload: "\e927";
$icon-work: "\e928";
$icon-zoom: "\e929";
$icon-star_border: "\e92a";
$icon-star_half: "\e92b";
$icon-star: "\e92c";
$icon-walk: "\e92d";
$icon-radio_button_unchecked: "\e92e";
$icon-radio_button_checked: "\e92f";
$icon-check_box_outline_blank: "\e930";
$icon-check_box: "\e931";
$icon-highlight_off: "\e932";
$icon-remove_circle_outline: "\e933";
$icon-search: "\e934";
$icon-directions_bike: "\e935";
$icon-local_parking: "\e936";
$icon-accessible: "\e937";
$icon-all_out: "\e938";
$icon-compare_arrows: "\e939";
$icon-copyright: "\e93a";
$icon-date_range: "\e93b";
$icon-delete_forever: "\e93c";
$icon-donut_large: "\e93d";
$icon-donut_small: "\e93e";
$icon-euro_symbol: "\e93f";
$icon-event_seat: "\e940";
$icon-fingerprint: "\e941";
$icon-flight_land: "\e942";
$icon-flight_takeoff: "\e943";
$icon-g_translate: "\e944";
$icon-gavel: "\e945";
$icon-gif: "\e946";
$icon-goat: "\e947";
$icon-http: "\e948";
$icon-important_devices: "\e949";
$icon-indeterminate_check_box: "\e94a";
$icon-lightbulb_outline: "\e94b";
$icon-line_style: "\e94c";
$icon-line_weight: "\e94d";
$icon-motorcycle: "\e94e";
$icon-offline_pin: "\e94f";
$icon-opacity: "\e950";
$icon-pan_tool: "\e951";
$icon-pets: "\e952";
$icon-picture_in_picture_alt: "\e953";
$icon-play_for_work: "\e954";
$icon-pregnant_woman: "\e955";
$icon-record_voice_over: "\e956";
$icon-remove_shopping_cart: "\e957";
$icon-restore_page: "\e958";
$icon-rounded_corner: "\e959";
$icon-rowing: "\e95a";
$icon-speaker_notes_off: "\e95b";
$icon-timeline: "\e95c";
$icon-touch_app: "\e95d";
$icon-update: "\e95e";
$icon-watch_later: "\e95f";
$icon-zoom_out: "\e960";
$icon-tickets: "\e983";
$icon-hand-luggage: "\e962";
$icon-from: "\e963";
$icon-to: "\e964";
$icon-seating: "\e965";
$icon-repeat_overlay: "\e966";
$icon-flight_cancel: "\e967";
$icon-rebook: "\e968";
$icon-rebook_circle: "\e969";
$icon-refund: "\e96a";
$icon-star_circle: "\e96b";
$icon-station_default: "\e96c";
$icon-locality_default: "\e96d";
$icon-establishment_default: "\e96e";
$icon-uniE96F: "\e96f";
$icon-uniE970: "\e970";
$icon-other-refund: "\e971";
$icon-per-diem: "\e972";
$icon-private-vehicle: "\e973";
$icon-ac_unit: "\eb3b";
$icon-airport_shuttle: "\eb3c";
$icon-all_inclusive: "\eb3d";
$icon-beach_access: "\eb3e";
$icon-business_center: "\eb3f";
$icon-casino: "\eb40";
$icon-child_care: "\eb41";
$icon-child_friendly: "\eb42";
$icon-fitness_center: "\eb43";
$icon-free_breakfast: "\eb44";
$icon-golf_course: "\eb45";
$icon-hot_tub: "\eb46";
$icon-kitchen: "\eb47";
$icon-pool: "\eb48";
$icon-room_service: "\eb49";
$icon-smoke_free: "\eb4a";
$icon-smoking_rooms: "\eb4b";
$icon-spa: "\eb4c";
$icon-glass: "\f000";
$icon-lock: "\f023";
$icon-eye: "\f06e";
$icon-globe: "\f0ac";
$icon-filter: "\f0b0";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-coffee: "\f0f4";
$icon-cutlery: "\f0f5";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-double-down: "\f103";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-angle-down: "\f107";
$icon-desktop: "\f108";
$icon-child: "\f1ae";
$icon-newspaper-o: "\f1ea";
$icon-wifi: "\f1eb";
$icon-television: "\f26c";
$icon-map: "\f279";
$icon-shopping-bag: "\f290";
$icon-thermometer-2: "\f2c9";
$icon-shower: "\f2cc";
$icon-bath: "\f2cd";

