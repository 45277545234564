//  ================================================
//  Extends
//  ================================================


//  ------------------------------------------------
//
//  extend: reset
//
//  Usage:
//
//  @extend %reset
//
//  ------------------------------------------------

%reset {
    margin: 0;
    padding: 0;
    list-style: none;
}


//  ------------------------------------------------
//
//  extend: clearfix
//
//  Usage:
//
//  @extend %clearfix
//
//  ------------------------------------------------

%clearfix {
    *zoom: 1;

    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
}


//  ------------------------------------------------
//
//  extend: backface
//
//  Usage:
//
//  @extend %backface
//
//  ------------------------------------------------

%backface {
    backface-visibility: hidden;
}

%flex-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

%flex-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

%border-hover {
  @include tn(border-color, 0.2s);
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $light-gray;
  }
}

%list-style {
  background-color: #fff;
  border: 1px solid $grey200;
  margin-bottom: 16px;
}
