@import "resources.scss";
.tcp-checkout-timer {
    @extend %is-font-medium;

    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    border: 1px solid $blue;
    padding: 10px 15px;
    margin: 0px 15px 20px;

    &-clock {
        @extend %is-font-large;

        font-weight: bold;
        color: $blue;
    }

    @include media-breakpoint-up(sm) {
      margin: 15px 15px 20px;
    }

}
