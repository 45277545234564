.react-hint {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: 0.5s fadeIn;
}

.react-hint__content {
  @include light-glow;

  padding: 10px;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 11px;
  max-width: 250px;
}

.react-hint:after {
  content: '';
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid transparent;
}

.react-hint--top:after {
  top: auto;
  border-bottom: none;
  border-top-color: #fff;
}

.react-hint--left:after {
  left: auto;
  border-right: none;
  border-left-color: #fff;
}

.react-hint--right:after {
  right: auto;
  border-left: none;
  border-right-color: #fff;
}

.react-hint--bottom:after {
  bottom: auto;
  border-top: none;
  border-bottom-color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
