@import "resources.scss";
.search-bar {
  position: relative;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-inner {
    padding: 85px 24px 48px;
  }

  &-concierge {
    border-top: 1px solid $black100;
    padding: 48px 24px 52px;
    margin-top: auto;
  }

  &-button {
    padding-top: 20px;
  }

  &-spacer {
    height: 36px;

    &.is--small {
      height: 12px;
    }

    &.is--medium {
      height: 24px;
    }
  }

  &-route-link {
    position: relative;
  }

  &-onlylink {
    margin-left: auto;
    position: relative;
  }

  &-route + &-route {
    .collapse-inner > div:first-child {
      padding-top: 12px;
    }
    margin-bottom: -12px;
  }

  @include media-breakpoint-up(md) {
    max-width: 375px;
    margin-top: $header-height-mobile;
    min-height: calc(100vh - #{$header-height-mobile});

    &-inner {
      padding-top: 24px;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 480px;
    margin-top: 0;
    min-height: 100vh;
    justify-content: flex-start;

    &-concierge {
      padding: 48px 48px 52px;
    }

    &.has--brand-logo {
      &::before {
        content: '';
        position: sticky;
        background: white;
        top: 0;
        height: $header-height;
        width: 100%;
        z-index: 10;
        border-bottom: 1px solid $black100;
        .is--demo & {
          top: $banner-height;
        }
      }

      .logo-primary {
        display: none;
      }
    }

    &-inner {
      padding: 48px 48px 48px;
    }

    .logo-primary {
      margin-bottom: 48px;
    }
  }
}
