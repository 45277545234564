@import "resources.scss";
.dropdown {
  position: relative;
  cursor: pointer;

  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;

    svg {
      @include tn(transform);

      margin-left: 4px;
      width: 16px;
    }

    .label {
      margin-bottom: 0;
    }
  }

  &-inner {
    @include tn(opacity visibility);
    position: absolute;
    opacity: 0;
    visibility: hidden;
    box-shadow: $shadow02;
    padding: 16px 8px 0;
    width: 200px;
    background: white;
    z-index: 10;

    .fac {
      display: block;
      margin: 16px 0;

      label {
        display: flex;
        padding-left: 0;
      }
      span:after,
      label:before {
        left: auto;
        right: 0;
      }
    }
  }

  &.is--open {
    .dropdown {
      &-label {
        svg {
          transform: rotate(180deg);
        }
      }
      &-inner {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  & + .dropdown {
    margin-left: 8px;
  }

  @include media-breakpoint-down(md) {
    &.align-results--right {
      .dropdown-inner {
        right: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .dropdown {
      &-label {
        padding: 0;
      }
    }
    & + .dropdown {
      margin-left: 16px;
    }
  }
}
