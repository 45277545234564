/*!
 * Font Awesome Controls
 * fac.css
 * Copyright Maxwel D'souza
 * MIT Licence
 */
.fac {
    user-select: none;
    position: relative;
}

.fac label {
    display: inline-block;
    margin-right: 8px;
    padding-left: 24px;
    cursor:pointer;
    font-weight:inherit;
}

.fac > input + label:before,
.fac > span::after {
  font-family: 'TCP' !important;
  position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
}

.fac > input + label:before {
    transition: opacity 0.3s ease-in-out;
}

.fac input[type=checkbox], .fac input[type=radio] {
    display:none;
}

/********* Behavior ***********/

.fac-checkbox > input + label:before,
.fac-checkbox-round > input + label:before {
    opacity: 1;
}

.fac-checkbox > input:checked + label:before,
.fac > input:checked + label:before {
    opacity: 0;
}

.fac-radio > input + label:before,
.fac-radio-button > input + label:before,
.fac-checkbox-o > input + label:before,
.fac-checkbox-round-o > input + label:before {
    opacity: 0;
}
.fac-radio > input:checked + label:before,
.fac-radio-button > input:checked + label:before,
.fac-checkbox-o > input:checked + label:before,
.fac-checkbox-round-o > input:checked + label:before {
    opacity: 1;
}
/********* Sizes ***********/

.fac-2x {
    font-size: 2em;
}

.fac-radio {
    font-size: 16px;
}

.fac-radio-button {
    font-size: 20px;
}

/********* Colors ***********/

.fac-default > input + label:before,
.fac-default > span::after {
    color: grey;
}
.fac-primary > input + label:before,
.fac-primary > span::after {
    color: $blue;
}
.fac-success > input + label:before,
.fac-success > span::after {
    color: #5cb85c;
}
.fac-info > input + label:before,
.fac-info > span::after {
    color: #5bc0de;
}
.fac-warning > input + label:before,
.fac-warning > span::after {
    color: #f0ad4e;
}
.fac-danger > input + label:before,
.fac-danger > span::after {
    color: #d9534f;
}

/********* Controls ***********/

.fac-checkbox > span::after {
    content: $icon-check_box; /* checked */
}
.fac-checkbox > input[type=checkbox] + label:before {
    content: $icon-check_box_outline_blank;
}

.fac-checkbox-round > span::after {
    content: "\f058";
}
.fac-checkbox-round > input[type=checkbox] + label:before {
    content: "\f111";
}

.fac-checkbox-o > span::after {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    border: 1px solid $black;
    border-radius: 2px;
}

.fac-checkbox-o > input[type=checkbox] + label:before {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_1872_939' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM13.6501 5.4L6.6001 12.45L2.3501 8.2L3.7501 6.8L6.6001 9.65L12.2501 4L13.6501 5.4Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM13.6501 5.4L6.6001 12.45L2.3501 8.2L3.7501 6.8L6.6001 9.65L12.2501 4L13.6501 5.4Z' fill='%23016BB1'/%3E%3Cpath d='M6.6001 12.45L5.18588 13.8642L6.6001 15.2784L8.01431 13.8642L6.6001 12.45ZM13.6501 5.4L15.0643 6.81421L16.4785 5.4L15.0643 3.98579L13.6501 5.4ZM2.3501 8.2L0.935884 6.78579L-0.478329 8.2L0.935884 9.61421L2.3501 8.2ZM3.7501 6.8L5.16431 5.38579L3.7501 3.97157L2.33588 5.38579L3.7501 6.8ZM6.6001 9.65L5.18588 11.0642L6.6001 12.4784L8.01431 11.0642L6.6001 9.65ZM12.2501 4L13.6643 2.58579L12.2501 1.17157L10.8359 2.58579L12.2501 4ZM2 2V-2C-0.209139 -2 -2 -0.209139 -2 2H2ZM2 14V2H-2V14H2ZM2 14H-2C-2 16.2091 -0.209139 18 2 18V14ZM14 14H2V18H14V14ZM14 14V18C16.2091 18 18 16.2091 18 14H14ZM14 2V14H18V2H14ZM14 2H18C18 -0.209139 16.2091 -2 14 -2V2ZM2 2H14V-2H2V2ZM8.01431 13.8642L15.0643 6.81421L12.2359 3.98579L5.18588 11.0358L8.01431 13.8642ZM0.935884 9.61421L5.18588 13.8642L8.01431 11.0358L3.76431 6.78579L0.935884 9.61421ZM2.33588 5.38579L0.935884 6.78579L3.76431 9.61421L5.16431 8.21421L2.33588 5.38579ZM8.01431 8.23579L5.16431 5.38579L2.33588 8.21421L5.18588 11.0642L8.01431 8.23579ZM10.8359 2.58579L5.18588 8.23579L8.01431 11.0642L13.6643 5.41421L10.8359 2.58579ZM15.0643 3.98579L13.6643 2.58579L10.8359 5.41421L12.2359 6.81421L15.0643 3.98579Z' fill='%23016BB1' mask='url(%23path-1-inside-1_1872_939)'/%3E%3C/svg%3E%0A");
}

.fac-checkbox-o:hover > input[type=checkbox] + label:before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_1872_939' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM13.6501 5.4L6.6001 12.45L2.3501 8.2L3.7501 6.8L6.6001 9.65L12.2501 4L13.6501 5.4Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM13.6501 5.4L6.6001 12.45L2.3501 8.2L3.7501 6.8L6.6001 9.65L12.2501 4L13.6501 5.4Z' fill='%23004781'/%3E%3Cpath d='M6.6001 12.45L5.18588 13.8642L6.6001 15.2784L8.01431 13.8642L6.6001 12.45ZM13.6501 5.4L15.0643 6.81421L16.4785 5.4L15.0643 3.98579L13.6501 5.4ZM2.3501 8.2L0.935884 6.78579L-0.478329 8.2L0.935884 9.61421L2.3501 8.2ZM3.7501 6.8L5.16431 5.38579L3.7501 3.97157L2.33588 5.38579L3.7501 6.8ZM6.6001 9.65L5.18588 11.0642L6.6001 12.4784L8.01431 11.0642L6.6001 9.65ZM12.2501 4L13.6643 2.58579L12.2501 1.17157L10.8359 2.58579L12.2501 4ZM2 2V-2C-0.209139 -2 -2 -0.209139 -2 2H2ZM2 14V2H-2V14H2ZM2 14H-2C-2 16.2091 -0.209139 18 2 18V14ZM14 14H2V18H14V14ZM14 14V18C16.2091 18 18 16.2091 18 14H14ZM14 2V14H18V2H14ZM14 2H18C18 -0.209139 16.2091 -2 14 -2V2ZM2 2H14V-2H2V2ZM8.01431 13.8642L15.0643 6.81421L12.2359 3.98579L5.18588 11.0358L8.01431 13.8642ZM0.935884 9.61421L5.18588 13.8642L8.01431 11.0358L3.76431 6.78579L0.935884 9.61421ZM2.33588 5.38579L0.935884 6.78579L3.76431 9.61421L5.16431 8.21421L2.33588 5.38579ZM8.01431 8.23579L5.16431 5.38579L2.33588 8.21421L5.18588 11.0642L8.01431 8.23579ZM10.8359 2.58579L5.18588 8.23579L8.01431 11.0642L13.6643 5.41421L10.8359 2.58579ZM15.0643 3.98579L13.6643 2.58579L10.8359 5.41421L12.2359 6.81421L15.0643 3.98579Z' fill='%23004781' mask='url(%23path-1-inside-1_1872_939)'/%3E%3C/svg%3E%0A");
}

.fac-checkbox-round-o > span::after {
    content: "\f10c";
}
.fac-checkbox-round-o > input[type=checkbox] + label:before {
    content: "\f05d";
}

.fac-radio > span::after {
    content: $icon-radio_button_unchecked;
}
.fac-radio > input[type=radio] + label:before {
    content: $icon-radio_button_checked;
}

.fac-radio-button-check > span::after {
  content: $icon-check;
  color: $light-gray;
}
.fac-radio-button-check > input[type=radio] + label:before {
  content: $icon-check;
  color: $blue;
  top: 0px;
}

.fac-radio-button-ban > span::after {
  content: $icon-disabled;
  color: $light-gray;
}
.fac-radio-button-ban > input[type=radio] + label:before {
  content: $icon-disabled;
  color: $red;
  top: 0px;
}



.fac-radio {
  font-size: 16px;

  & > input + label:before, & > span::after {
    font-size: 18px;
    line-height: 20px;
  }

  label {
    @include body;
    padding-left: 26px;

    &:before {
      font-size: 18px;
      line-height: 20px;
    }

    &.radio-label__spacing {
      margin-right: 50px;
    }
  }
}

.fac-default > input + label:before,
.fac-default > span::after {
  color: $body;
}
