.sidebar-search {
  display: flex;
  flex-direction: column;

  .sidebar-title {
    padding: 20px 15px;
  }

  & > .sidebar-title {
    font-size: 18px;
    line-height: 24px;
    padding: 15px 15px 20px;
    text-transform: none;
  }

  .sidebar {
    &-link {
      float: right;
      margin-top: 17px;

      &-label {
        font-size: 14px;
        color: $gray;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-badge {
      background: $gray;
      bottom: 0;
      color: white;
      height: 60px;
      line-height: 60px;
      padding-left: 26px;
      position: absolute;
      width: 100%;

      a {
        color: white;
      }

      &:before {
        @include position(center left 0);

        content: "";
        height: 0;
        border-style: solid;
        border-width: 14px 0 14px 15px;
        border-color: transparent transparent transparent white;
        width: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    width: $widget-width;
    position: relative;
    margin: 50px 0 60px 30px;;
    margin-bottom: 60px;

    & > .sidebar-title {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
