//  ================================================
//  Sidebar Section
//  ================================================

.sidebar-section {
  margin-bottom: 32px;

  &-title {
    @include subtitle2;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      @include tn(transform);
      transform: rotate(0deg);
      width: 20px;
      height: 20px;
    }

    i {
      margin-left: 8px;
      margin-right: auto;
    }
  }

  &-inner {
    display: block;
    padding-top: 16px;
    position: relative;

    .checkbox-filter {
      padding: 4px 0;
    }
  }


  &-passengers-link {
    @extend %is-font-small;

    text-align: right;

    span {
      @extend %is-underlined;

      cursor: pointer;
    }
  }

  &-headline {
    @include caption;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &-group + &-group {
    margin-top: 16px;
  }


  &.is--open {
    .sidebar-section {
      &-inner {
        display: block;
      }

      &-title svg {
        transform: rotate(180deg);
      }
    }
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }


  // &-row {
  //   &:after, &:before {
  //     display: table;
  //     content: " ";
  //   }

  //   &:after {
  //     clear: both;
  //   }
  // }

  // &-col {
  //   float: left;
  //   min-height: 1px;
  //   position: relative;
  //   width: 50%;

  //   &:first-child {
  //     border-right: 1px solid $light-gray;
  //   }
  // }

  // &-action-link {
  //   color: $gray;
  //   display: inline-block;

  //   &:first-child:after {
  //     @extend %is-bold;

  //     content: "|";
  //     color: $body;
  //     padding: 0 5px;
  //   }

  //   &-primary {
  //     @extend %is-bold;

  //     @extend %is-underlined;

  //     color: $body;
  //   }
  // }

  // &.is-first {
  //   .sidebar-section-title {
  //     padding-top: 0;
  //   }
  // }

  @include media-breakpoint-up(sm) {
    flex-shrink: 0;

    // &-inner {
    //   display: none;
  
  }
}
