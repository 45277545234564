@import "resources.scss";
/* The switch - the box around the slider */

$swtich-size: 36px;
$swtich-padding: 2px;

.tcp-radio {
    position: relative;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    &-disabled {
        opacity: .5;
    }

    > span {
        float: left;
        text-transform: capitalize;
    }

    &-title {
        padding-right: 10px;
    }

    &-value {
        padding-left: 10px;
        padding-right: 10px;
    }

    &-label {
        background: $light-gray;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        color: white;
        display: inline-block;
        line-height: $swtich-size / 2 + $swtich-padding;
        height: $swtich-size / 2 + $swtich-padding;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        min-width: 100px;
        white-space: nowrap;

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 0 12px 10px;
            border-color: transparent transparent transparent $light-gray;
            position: absolute;
            right: -10px;
            top: 0;
        }
    }

    &-switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 20px;
        display: flex;
        align-items: center;
        /* Hide default HTML checkbox */
        &:after {
            content: "";
            display: inline-block;
        }

        &-inner {
            background-color: $grey500;
            bottom: 0;
            border-radius: 2px;
            height: 20px;
            cursor: pointer;
            position: relative;
            transition: .4s;
            width: 36px;
            border-radius: 10px;
            display: flex;
            align-items: center;

            &:before {
                background-color: white;
                border-radius: 50%;
                position: absolute;
                content: "";
                height: $swtich-size / 2 - $swtich-padding;
                width: $swtich-size / 2 - $swtich-padding;
                left: $swtich-padding;
                -webkit-transition: .4s;
                transition: .4s;
            }
        }

        input:focus + &-inner {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked + &-inner:before {
            -webkit-transform: translateX($swtich-size / 2 - $swtich-padding);
            -ms-transform: translateX($swtich-size / 2 - $swtich-padding);
            transform: translateX($swtich-size / 2 - $swtich-padding);
        }

        input:checked + .tcp-radio-switch-inner {
            background-color: $blue;
        }

        input {
            display: none;
        }
    }

    i {
        font-size: 20px;
        margin-right: 8px;
        vertical-align: bottom;
    }
}
