@import "resources.scss";
.tcp-label-button {
    @include caption;
    padding: 6px 8px;
    background: $black100;
    transition: background .2s ease-in-out;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;

    i {
        font-size: 16px;
        margin-right: 4px;
        vertical-align: bottom;
    }

    .tcp-spinner-circle-button {
        width: 12px;
        height: 12px;
        margin-right: 6px;
        border-left: 1px solid $dark-gray;

    }

    &:hover {
        background: $black200;
    }
}
