@import "resources.scss";
.hotel-warning-modal {
  &-modal {
    border-radius: $border-radius;
    background: white;
    padding: 48px 24px 12px;
    text-align: center;
    max-width: 640px;
  }

  &-overlay {
    background: rgba($dark-gray, 0.3);
  }

  &-title {
    @include subtitle1;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &-text {
    padding: 0 24px 28px;
    overflow-y: auto;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 32px;
    gap: 12px;
  }

  svg {
    margin-bottom: 32px;
  }

  .react-responsive-modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
