@import "resources.scss";
.tcp-hotel-fare {
    border-collapse: collapse;
    background-color: transparent;
    margin-bottom: 30px;
    overflow: scroll;
    width: 100%;
    @extend %is-font-medium;

    th {
        font-weight: bold;
        border-bottom: 1px solid $light-gray;
        border-top: 0;

        // &:nth-child(6n) {
        //     hyphens: auto;
        // }
    }

    td, th {
        padding: 15px 10px;
        text-align: left;
        vertical-align: top;
        border-bottom: 1px solid $light-gray;

        &:last-child {
            border-right: 0;
        }
    }

    td {
        border-right: 1px solid $light-gray;
    }

    tfoot {
        td {
            font-weight: bold;

            @extend %is-bold;

            border-right: 0;
            font-size: 18px;
        }
    }
}
