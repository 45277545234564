@import "resources.scss";
.lp-checkout-price {
  margin: 15px 20px 20px;
  padding: 15px 20px;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  background: $gray-background;
  border: 1px solid $border-gray;

  &-label {}

  &-number {
    @extend %is-font-extralarge;

    font-weight: bold;
  }

  &-info {
    flex-direction: column;
    text-align: right;
  }

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
    margin: 15px 0px 20px;

    &-label {}

    &-number {
      font-size: 24px;
      width: 135px;
      text-align: right;
    }
  }
}
