@import "resources.scss";
.logo {
  display: flex;
  align-items: center;
  height: 100%;

  &-co {
    height: 100%;
    width: auto;
  }

  &-main {
    width: 191px;
    height: 24px;
    margin-left: 20px;
  }

  &-brand {
    max-width: 140px;
    max-height: 40px;
    margin-left: 16px;
  }

  &.in--header {
    cursor: pointer;

    .logo-main {
      margin-left: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    position: relative;

    &-co {
      width: 182px;
      top: 0;
      left: 0;
      height: 100%;
      width: auto;
      position: relative;
    }

    &-brand {
      max-height: 40px;
      max-width: 160px;
    }
  }
}
