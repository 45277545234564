[class^="lp-icon-"], [class*=' lp-icon-'] {
  width: 34px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.lp-icon {
  &-warning {
    background-image: url("../assets/icons/lp-warning.svg");
    height: 34px;
  }

  &-help {
    background-image: url("../assets/icons/lp-help.svg");
    height: 34px;
  }

  &-event {
    background-image: url("../assets/icons/lp-event.svg");
    height: 34px;
  }

  &-terms {
    width: 22px !important;
    height: 22px !important;

    &-baggage-false {
      background-image: url("../assets/icons/baggage-false.svg");
    }

    &-baggage-true {
      background-image: url("../assets/icons/baggage-true.svg");
    }

    &-hand-luggage-false {
      background-image: url("../assets/icons/hand-luggage-false.svg");
    }

    &-hand-luggage-true {
      background-image: url("../assets/icons/hand-luggage-true.svg");
    }

    &-more-default {
      background-image: url("../assets/icons/more-default.svg");
    }

    &-more-hover {
      background-image: url("../assets/icons/more-hover.svg");
    }

    &-rebook-false {
      background-image: url("../assets/icons/rebook-false.svg");
    }

    &-rebook-true {
      background-image: url("../assets/icons/rebook-true.svg");
    }

    &-refund-false {
      background-image: url("../assets/icons/refund-false.svg");
    }

    &-refund-true {
      background-image: url("../assets/icons/refund-true.svg");
    }

    &-seating-false {
      background-image: url("../assets/icons/seating-false.svg");
    }

    &-seating-true {
      background-image: url("../assets/icons/seating-true.svg");
    }

    &-city-ticket-dep {
      background-image: url("../assets/icons/city-ticket-dep.svg");
    }

    &-city-ticket-arr {
      background-image: url("../assets/icons/city-ticket-arr.svg");
    }

  }
}
