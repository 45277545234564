.main-container {
  position: relative;
  padding-top: $header-height;

  &.without-nav-spacing {
    padding-top: 0px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: $header-height-mobile;

    &.no-scrolling {
      padding-top: $header-height;
      position: fixed;
      overflow: hidden;
      max-height: 100vh;
      z-index: 10;
    }
  }
}

.container-fluid {
  max-width: 1200px;
}


#launcher {
  z-index: 0 !important;
  bottom: 88px !important;
  @include media-breakpoint-up(sm) {
    right: 16px!important;
  }
}

.is--demo {
  padding-top: $banner-height;
}
