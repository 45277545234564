@import "resources.scss";
.loader-results {
    margin-bottom: 20px;
    
    &-inner {
        padding: 0 20px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .tcp-spinner {
            margin-right: 15px;
            padding: 0;
            display: flex;
        }
        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }
}
