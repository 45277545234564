@import "resources.scss";
.tcp-hotel-image-gallery {
    .image-gallery-content:not(.fullscreen) {

        .image-gallery-image img {
            object-fit: cover;
            width: 100%;
            height: 220px;
        }
    }
    .image-gallery-content.fullscreen {
        .image-gallery-image {
            height: 100vh;
            text-align: center;

            img {
                width: auto;
                top: 50%;
                position: relative;
                transform: translateY(-50%);
                max-height: 100%;
            }
        }

        .image-gallery-fullscreen-button {
            bottom: 60px;
        }
    }
    

    @include media-breakpoint-up(sm) {
        margin-left: -20px;
            .image-gallery-content:not(.fullscreen) {

                .image-gallery-image img {
                    height: 340px;
            }
        }
    }
}
