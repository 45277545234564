@import "resources.scss";
.lp-hotels-conn {
  &-request {
    @extend %list-style;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: white;
    border-radius: 5px;

    strong {
      margin-bottom: 11px;
    }
    
    .button {
      margin-top: 20px;
    }
    
    @include media-breakpoint-up(sm) {
      padding: 25px 30% 25px 25px;
      background: url('../../../assets/img/backgrounds/hotel-request.png') no-repeat;
      background-color: white;
      background-size: contain;
      background-position: right center;
    }
  }
  
  &-show-more {
    @extend %list-style;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: white;
    border-radius: 5px;

    .button {
      margin-top: 20px;
    }
  }

}
