@import "resources.scss";
.lp-modal {
  border-radius: $border-radius;
  background: white;

  &-overlay {
    background: rgba($dark-gray, 0.3);
  }

  &-title {
    margin-right: 30px;
    @include subtitle1;
    font-weight: 500;
  }

  &-header {
    padding: 36px 24px 16px;
  }

  &-body {
    padding: 0 24px 28px;
    overflow-y: auto;
  }
}
