@import "resources.scss";
.tcp-login {
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  overflow: hidden;

  &:before, &:after {
    content: "";
    background-color: $lighter-gray;
  }

  &:before {
    @include pos(45% n n 50%);
    @include circle(100vh);

    height: 110%;
    margin-left: calc( ( 100vh - 20px  ) * -1);
    margin-top: calc( ( 100vh / 2 ) * -1);
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.15);
  }

  &:after {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: calc( 100vh / 2);
      margin-left: calc( ( (100vh - 20px) ) * -1);
    }
  }

  &-content {
    text-align: center;
    align-self: center;
    padding: 0 30px;
    z-index: 1;
    @extend %backface;
  }

  &-logo {
    margin-bottom: 30px;

    @include bg("l&p-icon.png", $size: 60px, $pos: center);
    @include circle(94px);

    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  &-title {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 15px;

    & + p {
      margin-bottom: 55px;
    }
  }

  &-form {
    &-field {
      position: relative;
      margin-bottom: 30px;

      &-group {
        display: flex;
        justify-content: space-between;
      }

      .form-error-message {
        color: #DD3728;
        margin-top: 5px;
      }
    }

    &-text {
      @include font-large;

      width: 100%;
      display: block;
      border: none;
      border-bottom: 1px solid $gray;
      height: 40px;
      background-color: transparent;
      outline: 0;

      @include input-placeholder {
        color: $gray;
      }

    }

    &-label {
      font-size: 12px;
      color: $gray;
      pointer-events: none;

      @include tn(top, 0.2s);

      @include pos((-10px) n n 0);
    }

    &-forgot {
      @include pos(10px 0 n n);

      color: $gray;

      &:hover {
        color: $body;
      }
    }

    &-submit {
      padding-top: 15px;
    }

    &-button {
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 10px;
      width: 200px;
      text-align: center;
      height: 40px;
    }

    &-continue {
      @include pos(center bottom 30px);

      font-size: 24px;
      color: $gray;
    }

    .button {
      margin: auto;
      min-width: 160px;
    }
  }

  &-password-link {
    // @include font-small;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
  }

  &-edit-email-link {
    @include font-small;
    font-weight: bold;
  }

  @include media-breakpoint-up(sm) {

    &-content {
      width: 360px;
      padding: 0;
    }
  }
}
