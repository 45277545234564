@import "resources.scss";
.lp-sortbar {
  @include hide-scrollbar();

  display: flex;
  align-items: center;
  overflow-x: scroll;
  margin-top: 16px;
  padding: 0 16px;
  margin-left: -16px;

  &-label {
    margin-right: 16px;
    white-space: nowrap;
  }

  &-button {
    @include tn(background);
    @include body;
    border-radius: 8px;
    display: flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark-gray;
    white-space: nowrap;

    &:hover {
      background: $black100;
    }

    &.is--active {
      background: $blue25;
      &:hover {
        background: $blue25;
      }
    }

    & + & {
      margin-left: 8px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-right: 0;
  }
}
