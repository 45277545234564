@import "resources.scss";
.tcp-search-bar-date-time {
  display: flex;
  justify-content: space-between;

  &-wrapper {
    flex-direction: column;
  }

  &-item {
    width: calc(50% - 6px);
    text-align: right;

    .tcp-link {
      display: inline-block;
    }
  }
}
