@import "resources.scss";
.lp-scroll-up-btn {
  @include tn(background);
  
  position: fixed;
  right: 30px;
  bottom: 36px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: $black400;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  i {
    font-size: 20px;
  }

  &:hover {
    background-color: $black500;
  }
}
