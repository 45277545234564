@import "resources.scss";
//  ================================================
//  Form
//  ================================================

.form {
  @extend %clearfix;

  &-bigger {
    padding-bottom: 30px;
  }

  &-modal {
    max-width: 500px;
  }

  &-title {
    @extend %is-bold;
    padding-bottom: 20px;
  }

  &-paragraph {
    &-description {
      padding-bottom: 20px;
      color: $gray;
    }
  }

  &-field {
    margin-bottom: 20px;
    @extend %clearfix;
    position: relative;
    width: 100%;

    &-checkbox {
      padding: 20px 0px;
    }

    &-left {
      padding-left: 10px;
    }

    &-small {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      & > div {
        flex: 1;
      }
      .fac {
        margin-left: 15px;
        line-height: 32px;
      }
    }

    &-big-mrgin {
      margin-bottom: 50px;
    }

    &-big {
      max-width: 800px;
    }

    &-inline {
      input {
        margin-right: 10px;
      }
    }

    .fac-checkbox {
      padding-top: 5px;
    }

    .form-error-message {
      @extend %is-font-medium;
      color: $red;
      margin-top: 5px;
    }

    .Select-control {
      height: $new-input-height;
      line-height: calc(#{$new-input-height} - 2px);
      .Select-value,
      .Select-placeholder,
      .Select-input {
        line-height: $new-input-height;
        // line-height: calc(#{$new-input-height} - 2px);
      }

      .Select-input {
        height: $new-input-height;
      }
    }

    .SelectDatePicker {
      display: grid;
      column-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-field-icon {
    position: relative;
    max-width: 360px;

    &.clickable {
      cursor: pointer;
      input[type='text']:disabled {
        cursor: pointer;
      }
    }

    i {
      @include pos(n n n 10px);
      color: $gray;
      line-height: 18px;
      &:before {
        vertical-align: baseline;
      }
    }

    input {
      padding-left: 30px;
    }

    &-small {
      width: 81px;
      display: inline-block;
    }

    &-right {
      i {
        left: auto;
        right: 5px;
      }

      .form-textfield {
        padding-left: 10px;
        padding-right: 30px;
      }
    }
  }

  &-edit {
    @include pos(0 0 n n);
    width: 80px;

    &-input {
      @include pos(6px 0 n n);
    }
  }

  &-select {
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;

    &-full {
      width: 100%;
    }

    &-left {
      margin-right: 10px;
    }

    .select-options {
      top: 29px;
    }
  }

  &-text {
    display: inline-block;
    font-weight: bold;
    padding-top: 7px;
    margin-top: 0px;
  }

  &-textfield {
    width: 100%;
    height: $new-input-height;
    // max-width: 360px;
    border-radius: $border-radius !important;
    background-color: #fff;
    border: 1px solid $light-gray;
    padding-left: 10px;

    &:focus {
      border-color: $dark-gray;
    }

    &.has-error {
      border: 1px solid red;
    }

    &-exact {
      width: auto;
    }

    &-small {
      width: 81px;
    }

    &-left {
      margin-right: 10px;
    }

    + p {
      padding-top: 5px;
    }
  }

  &-radio {
    margin-right: 36px;
    cursor: pointer;
    margin-top: 6px;
    display: inline-block;

    input {
      margin-right: 10px;
    }
  }

  &-textarea {
    width: 90%;
    max-width: 300px;
    height: 115px;
    border-radius: $border-radius;
    background-color: #fff;
    border: 1px solid $light-gray;
    //box-shadow: inset 1px 2px 5px rgba(12,14,16,.15);

    &-full {
      width: 100%;
      max-width: none;
    }

    &::placeholder {
      @extend %is-font-medium;
      margin-top: 5px;
    }
  }

  &-upload {
    //background-color: $light-gray;
    //width: 98px;
    //height: 98px;
    //position: relative;
    //border-radius: 6px;
    //
    //&:before {
    //  content: "";
    //  pointer-events: none;
    //  @include pos(10px 10px 10px 10px);
    //  border-radius: 6px;
    //  border: 1px dashed $light-gray;
    //}
    //
    //input {
    //  display: none;
    //}
  }

  &-upload-button {
    cursor: pointer;
    font-size: 40px;
    line-height: 170px;
    color: $light-gray;
    display: block;
    text-align: center;
    .icon-upload {
      font-size: 40px;
      margin-right: 0;
    }
  }

  &-dropzone {
    background-color: $light-gray;
    position: relative;
    height: 170px;
    border: 1px dashed $light-gray;
    border-radius: 5px;

    &.has-error {
      border: 1px solid red;
    }

    span {
      position: absolute;
      bottom: 3%;
      left: 3%;
    }

    .img-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 150px;
      max-height: 150px;
      margin: auto;
      border-radius: 10px;
    }
  }

  &-submit {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    &.text-right {
      .button {
        margin-left: 20px;
      }
    }
  }

  &-box {
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 550px;

    &.small-box {
      width: 500px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .select {
      padding-right: 8px;
      padding-left: 12px;
    }

    .steps {
      margin: -20px -20px 20px;
      display: block;

      .steps-link {
        padding-left: 20px;
        padding-right: 41px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-content-half {
      float: left;
      width: 50%;
    }

    &-row {
      display: grid;
      column-gap: 16px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.form-horizontal {
  &-row {
    @extend %clearfix;
    margin-left: -15px;
    margin-right: -15px;
  }

  &-field {
    float: left;
    min-width: 19%;
    padding: 15px;

    &-search {
      float: right;
      .search-field {
        margin-top: 25px;
      }
    }
  }

  &-label {
    @extend %is-bold;
    display: block;
    margin-bottom: 5px;
    padding-left: 15px;
  }
}
