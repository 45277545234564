@import "resources.scss";
.map-marker {
  @include body;
  top: -12px;
  left: -10px;
  display: inline-block;
  position: relative;
  color: $body;
  font-family: $base-font;

  &-box {
    border-radius: $border-radius;
    box-shadow: $shadow02;
    background: white;
    position: relative;
    top: -35px;
    left: 30px;
    width: auto;
    padding: 8px;
    display: inline-table;
  }

  &-date {
    @include caption;
  }

  &-location {
    white-space: nowrap;
  }
}
