@import "resources.scss";
.tcp-loader-overlay-body {
 

  &-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 16px;
    color: $black;
  }

  &-images {
    max-width: 150px;
    margin: auto;
    height: 70px;

    img {
      max-height: 100%;
    }
  }

  &-text {
    margin-bottom: 40px; 
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(sm) {
      max-width: 85%;
    }
  }

  &-promotion {
    padding: 20px 25px;
    background: $gray-background;
    display: flex;
    text-align: left;

    img {
      width: 70px;
      margin-right: 20px;
    }
  }

  &-loading  {
    max-width: 420px;
    margin: 30px auto 30px;
    height: 24px;
    position: relative;
    padding: 0 13px;

    .icon-to, .icon-from {
      position: absolute;
      top: 50%;
      transform: translateY(-5px);  
      }

    .icon-to {
      right: 0px;
    }

    .icon-from {
      left: 0px;
    }
  }

  &-track {
    height: 100%;
    position: relative;

    &::before, &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background: $gray-background;
      width: 100%;
      display: block;
    }

    &::after {
      background: $dark-gray;
      width: 0;
      animation: tcp-loader-overlay-progress 2s linear infinite;
    }
 
    .icons { 
      position: absolute;
      animation: movement 2s linear infinite;
      z-index: 1;

      i {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $body;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $dark-gray;
        background: white;
      }

      .icon-plane {
        transform: rotate(45deg);
      }
    }

  }
}

@keyframes movement {
  from {
    left: 0;
  }
  to {
    left: calc(100% - 24px);
  }
 }


@keyframes tcp-loader-overlay-progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

