@import "resources.scss";
.tcp-trip-time-info {
  &-duration {
    @include body;
    font-weight: 700;
  }

  &-stops {
    @include label;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 10px;

    &-duration {
      padding-bottom: 10px;
    }

    &-stops {
      @include body;
    }
  }
}
