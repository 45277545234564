@import "resources.scss";
.tcp-hotel-facilities {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        @extend %is-font-medium;
        display: inline-block;
        margin-bottom: 8px;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;

        i {
            color: $blue;
            vertical-align: middle;
            font-size: 10px;

            &:before {
                margin-right: 5px;
            }
        }
    }

    .tcp-link i {
        font-size: 12px;
    }

    &-inner {
      cursor: pointer;
    }

    &-column {
        display: block;

        li {
            display: block;
            width: auto;
            padding-right: 0;
            max-width: 135px;
        }
    }

    &-overlay {
      @include pos(0 0 0 0, fixed, $z: $z9);
      background: rgba($black, .3);
      overflow: auto;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-content {
        border-radius: 4px;
        background: white;
        display: inline-block;
        padding: 35px 25px;
        box-shadow: 0 0 15px 0 $shadow;
        width: 100%;
        max-width: 960px;
        position: relative;
        max-height: 100vh;
        overflow: auto;
      }

      &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 5px;
      }

      &-title {
        @include f-subtitle1;
        margin-bottom: 24px;
      }

      ul li {
        width: 50%;

        i {
          font-size: 16px;
        }

        @include media-breakpoint-up(md) {
          width: 33%;
        }
      }
    }


}
