@import "resources.scss";
.tcp-error-tooltip {
  @include light-glow;

  @extend %is-font-small;

  position: absolute;
  padding: 10px 20px;
  z-index: $z1;
  background: white;
  border-radius: 3px;
  right: 10%;
  left: 10%;
  top: calc(100% - 5px);
  text-align: center;
  width: auto;
  font-weight: bold;
  color: $red;

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: auto;
    border: 10px solid transparent;
    border-top: 0;
    z-index: 2;
    top: -10px;
    left: calc(50% - 5px);
    border-bottom-color: #fff;
  }
}
