@import "resources.scss";
.lp-trip-sidebar-info {
  display: flex;
  align-items: flex-start;
  padding-top: 8px;

  &-logo {
    max-width: 20px;
    max-height: 20px;
    margin-right: 8px;
  }

  & + & {
    padding-top: 16px;

  }
}
