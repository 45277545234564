@import "resources.scss";
.lp-journey-plan-information {
  &-remark {
    border: 1px solid #000;
  }
  @media print {
    &-remark {
      border: none;
    }
  }
}
