.container {
  max-width: 1170px;
  min-height: 700px;
  margin: auto;
  &-trip {
    margin-top: 20px;
  }
}

.row {
  display: flex;
}
