@import "resources.scss";
.tcp-hotel-description {
    &-location {
        color: $gray;
        margin-bottom: 10px;
    }

    &-title {
        @include headline;
    }

    &-text {
      @include font-medium;
    }

}
