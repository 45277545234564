@media print {
  @page {
    size: 992px;
  }

  html, body {
    width: 992px;
    height: 297mm;
  }

  body {
    background: white;
  }

  * {
    transition: none !important;
    -webkit-print-color-adjust: exact;
  }
}
