@import "resources.scss";
.lp-trip-list {
  &-trip-item {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100vw;
  }

  @include media-breakpoint-up(sm) {
    &-trip-item {
      // max-width: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .ReactVirtualized__List {
    will-change: initial !important;
    -webkit-overflow-scrolling: auto!important;
  }
}
