@import "resources.scss";
.tcp-hotel-rating {
  @extend %is-font-medium;

  display: flex;
  align-items: center;
  margin-right: 20px;

  &-count {
    border-radius: 2px;
    padding: 3px 8px;
    margin-right: 8px;
    color: white;
    background: $blue;

    &-40 {
      background: rgba($blue, 0.4);
    }

    &-70 {
      background: rgba($blue, 0.7);
    }
  }

  @include media-breakpoint-up(sm) {
    margin-right: auto;
  }
}
