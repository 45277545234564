@import "resources.scss";
.tab {
  @include tn(color background);
  @include caption;
  background: white;
  color: $black;
  border: 1px solid $black;
  padding: 8px 12px;
  position: relative;
  border-radius: 2px;
  font-weight: 500;
  height: 32px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;

  & + & {
    margin-left: 12px;
  }

  &.is--active,
  &:hover {
    color: white;
    background: $black;
  }
}
