@import "resources.scss";
.results-list {
  @extend %reset;
  @include tn(opacity visibility);
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% + 16px);
  margin: 0 -8px;

  &.is--dropdown {
    box-shadow: $shadow02;
    opacity: 0;
    visibility: hidden;
    padding: 8px 0;
    position: absolute;
    background: white;
    z-index: 1;
    margin: 0 0 60px;
    border-radius: 4px;
    border-bottom: 0;
    max-height: 200px;
    overflow-y: auto;

    &.is--open {
      opacity: 1;
      visibility: visible;
    }
  }

  &-item {
    @include tn(background);
    display: flex;
    align-items: center;
    padding: 8px;
    width: 100%;
    overflow: hidden;
    // white-space: pre;
    text-overflow: ellipsis;

    svg {
      margin-right: 8px;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }

  button.results-list-item {
    &:hover {
      background: $blue50;
    }
  }
}
