@import "resources.scss";
.lp-ws-status-bar {
  position: fixed;
  width: 100%;
  top: 0px;
  background-color: $red;
  z-index: 210;
  text-align: center;
  color: $white;
  a {
    color: $white;
    text-decoration: underline;
    font-weight: bold;
  }
}