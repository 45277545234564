@import "resources.scss";
.tcp-loader-overlay {
  @include pos(0 0 0 0, fixed, $z: $z9);
  background: rgba(#fff, 0.9);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  max-width: 100%;
  display: flex;
  justify-content: center;

  &-inner {
    border-radius: 4px;
    background: white;
    display: inline-block;
    padding: 48px 24px 40px;
    box-shadow: 0 0 15px 0 $shadow;
    width: 100%;
    max-width: 640px;
  }

  &-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 900px;
    padding: 15px;
    width: 100%;

    &-title {
      @include subtitle1;
      font-weight: 700;
      margin-bottom: 12px;
    }
  }

  &-icon {
    margin-bottom: 32px;

    .color-warning {
      color: $red;
    }

    i {
      font-size: 80px;
    }
  }

  &-button {
    margin: auto;
    margin-top: 32px;
  }

  @include media-breakpoint-up(md) {
    &-text {
      max-width: 80%;
      margin: 0 auto;
    }
  }
}
