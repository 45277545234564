

@media print {
  .sidebar-checkout, .content-checkout-ical, .tcp-header, .no-print-chk {
    display: none !important;
  }

  .content-checkout {
    max-width: 800px;
  }

}
