@import "resources.scss";
.tcp-loader-progress {
  @include tn(visibility, 500ms);

  width: 0%;
  animation: tcp-loader-progress 0s linear 0s 1 normal forwards;
  overflow: hidden;
  align-self: flex-start;

  &-inner {
    @include skeleton-animation;
    background-image: linear-gradient(55deg, #0172B1 0%, #0399ED 54%, #0172B1 100%);
    height: 5px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &-finished {
    animation-duration: 2s;
    animation-iteration-count: 0;
    visibility: hidden;
  }

  &-rounded {
    border-radius: 3px;
  }

  @include media-breakpoint-up(sm) {
    top: $header-height;
  }
}

@keyframes tcp-loader-progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
