@import "resources.scss";
.map-container {
  width: 100%;
  height: 80vh;
  .map-element {
    height: 100%;
    .gm-style-iw {
      > div:first-child {
        overflow: hidden !important;
      }
    }
  }
}
.map-marker-dest {
  color: $black;
  font-size: 30px;
}
