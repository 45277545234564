//  ================================================
//  Sidebar
//  ================================================

.sidebar {
  @include tn(opacity visibility, 150ms 150ms);
  min-height: 100vh;
  width: 100%;
  z-index: 101;
  visibility: hidden;
  position: fixed;
  top: 0;
  overflow: scroll;
  opacity: 0;

  .lp-navbar {
    position: sticky;
  }

  &-inner {
    padding: 24px 24px 32px;

    &:not(:first-child) {
      border-top: 1px solid $grey200;
    }
  }

  &-wrapper {
    background-color: white;
    z-index: 1;
    position: relative;
    height: 100vh;
    overflow: auto;
    
    .is--demo & {
      top: $banner-height;

      .lp-navbar {
        top: 0;
      }
    }
  }

  &-reset {
    margin-top: 16px;
  }

  &-title {
    @include caption;
    padding: 4px 0 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-check-all {
    @extend %is-font-small-strict;
    float: left;
    margin-top: 15px;
    margin-right: 10px;
    padding-bottom: 20px;
    span {
      color: #b1b3b4;
    }
    &.active > span {
      @extend %is-underlined;
      @extend %is-bold;
      color: $body;
    }
    &.divider {
      @extend %is-bold;
    }
  }

  &-filter-only {
    color: $blue;
    white-space: nowrap;
    margin: 0;
  }

  &-no-hotel {
    font-size: 12px;
  }

  &-route-section + &-route-section {
    padding-top: 8px;
  }

  .count-down {
    margin-top: -20px;
  }

  &.is--open {
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-up(md) {
    left: 0;
    max-width: 360px;
    position: absolute;
    z-index: 2;

    &-wrapper {
      position: fixed;
      top: $header-height;
      width: 360px;
      height: calc(100vh - #{$header-height});
      background-color: white;
      z-index: 1;
      border-right: 1px solid $black200;

      .is--demo & {
        top: calc($banner-height + $header-height);
      }
    }

    &::before {
      content: '';
      background-color: rgba($black, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: none;
    position: relative;
    align-self: flex-start;
    height: auto;

    &::before {
      display: none;
    }
  }
}

// .sidebar {
//   &-datetime-outbound, &-datetime-inbound {
//     text-align: center;
//     .sidebar-section {
//       padding-left: 0;
//       padding-right: 0;
//       @include media-breakpoint-up(sm) {
//         padding-left: 10px;
//         padding-right: 10px;
//       }
//     }
//   }
// }

// .sidebar-concierge {
//   @include media-breakpoint-up(sm) {
//     max-height: calc(100vh - 130px);
//     .sidebar-inner {
//       overflow: auto;
//     }
//   }
// }

// .sidebar-inbound, .sidebar-datetime-inbound, .sidebar-search {
//   .sidebar-title {
//     background-image: linear-gradient(125deg, #D53526 0%, #E2392A 49%, #F03D2F 100%);
//     &:before {
//       border-top-color: #DD3728;
//     }
//   }
//   .sidebar-route-price {
//     @extend %is-bold;
//   }
// }

// .sidebar-outbound, .sidebar-route-outbound, .sidebar-datetime-outbound {
//   .sidebar-title {
//     background-image: linear-gradient(45deg, #0172B1 0%, #036297 100%);
//     &:before {
//       border-top-color: #0172B1;
//     }
//   }
// }

// .sidebar-hotels, .sidebar-checkout, .sidebar-passenger, .sidebar-concierge {
//   .sidebar-title {
//     background-image: linear-gradient(-85deg, #5B5B5B 0%, #454246 100%);
//     &:before {
//       border-top-color: #454246;
//     }
//   }
// }

// .sidebar-route-outbound, .sidebar-route-inbound {
//   margin-top: 0;
// }

// .sidebar-outbound, .sidebar-inbound, .sidebar-checkout {
//   @include media-breakpoint-up(sm) {
//     box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
//     display: flex;
//     opacity: 1;
//     z-index: $z1;
//   }
// }

// .sidebar-checkout {
//   position: sticky;
//   bottom: 60px;
//   top: auto;
//   align-self: flex-end;
//   min-height: calc(100vh - 20px - 30px - #{$header-height});
// }

.hide-slider-left-regulator {
  .rc-slider-handle-2 {
    display: none;
  }
}

.hide-slider-right-regulator {
  .rc-slider-handle-1 {
    display: none;
  }
}
